import { Range, RangeText, EnhancedDateRange } from '../types/types';

export const daterangeDefault: EnhancedDateRange[] = [
  { RangeName: 'Last 7 Days', RangeKey: Range.THISWEEK, Text: RangeText.THISWEEK },
  { RangeName: 'Last 30 Days', RangeKey: Range.THISMONTH, Text: RangeText.THISMONTH },
  { RangeName: 'Last 365 Days', RangeKey: Range.THISYEAR, Text: RangeText.THISYEAR },
  { RangeName: 'All Time', RangeKey: Range.ALLTIME, Text: RangeText.ALLTIME },
];

export const defaultMetrics = [{ name: 'Clients' }, { name: 'Orders' }, { name: 'Campaigns' }, { name: 'Impressions' }];

export const impressionsByClientTooltips = {
  impressionsDelivered:
    'This percentage is the year-over-year increase or decrease of the total number of impressions delivered for all campaigns. If there is no prior-year data to compare with, then the percentage is shown as “0.00%”.',
  progressBar: `These progress bars show the total number of impressions 
  delivered for all campaigns for this chosen period versus the same previous period. 
  If there is no data to compare with, then the bottom progress bar is not shown. 
  The length of these bars across different advertisers will vary. 
  The bars only compare this period to the prior period, and do not compare one advertiser to another.`,
  impressionGoal:
    'This percentage is the ratio of impressions delivered for all campaigns compared to the impression goal for all campaigns. The total number of impressions delivered for all campaigns so far this year is shown below this percentage.',
};
