
import draggable from 'vuedraggable';
import Vue from 'vue';
import { SectionComponent, CurrentSection, LayoutSection, TopLevelSectionComponent } from '../../types/layout';
import utils from '../../util';
import availableComponents from './availableComponents.vue';
import SummaryCustomizationOptions from '../components/toolbar/layoutEditingOptions/summaryPageOptions.vue';
import analytics from '../../mixins/analytics';

export default Vue.extend({
  components: {
    availableComponents,
    draggable,
    SummaryCustomizationOptions,
  },
  props: ['validSections', 'currentSection', 'validComponents'],
  mixins: [analytics],
  data(): {
    drag: boolean;
    dragOptions: object;
    selectedTab: number;
  } {
    return {
      drag: false,
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        chosenClass: 'chosen',
        dragClass: 'drag',
      },
      selectedTab: 1,
    };
  },
  watch: {
    '$route.query.viewtab'(newVal) {
      this.selectedTab = newVal ? Number(newVal) : 1;
    },
    '$store.state.layoutEditor.componentId': {
      handler(): void {
        if (!this.$store.state.layoutEditor.componentId || !this.$store.state.layoutEditor.sectionId) {
          this.dragOptions.disabled = false;
        } else {
          this.dragOptions.disabled = true;
        }
      },
    },
  },
  computed: {
    componentsList: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      get(): any {
        const componentList = utils.getLayoutComponents(this)?.map(c => {
          return {
            cid: c.cid,
            overrides: c.overrides,
            id: c.id,
          };
        });

        if (!this.$store.state.layoutEditor.editMode)
          this.$store.dispatch('layoutEditor/setCurrCustomizations', componentList);

        const validComps = this.validComponents?.components.filter(c => !c.disabled);

        if (this.shouldUsingTabs) {
          const filteredByTabsComps =
            validComps?.filter(component => {
              if (component.name === 'campaignNav') return true;
              if (this.selectedTab === 2) return component?.overrides?.tab === 2;
              if (this.selectedTab === 1) return component?.overrides?.tab === 1 || !component?.overrides?.tab;
              else return component?.overrides?.tab === 1 || !component?.overrides?.tab;
            }) || [];

          return filteredByTabsComps;
        }

        // ***
        // DASH-5207
        // this solution is not stable rn because we are not always getting
        // the feed sources of the campaign earlier than feed sources of the components
        // commenting out for now, maybe we'll need it later
        // ***

        // const validComps = this.validComponents?.components;

        // const feeds = utils.feedSources(this);
        // validComps = validComps.filter(item => {
        //   if (item?.feedSourceToExclude?.length) {
        //     return !item.feedSourceToExclude?.some(item => feeds.includes(item));
        //   }
        //   if (item?.feedSource?.length) {
        //     return item.feedSource?.some(item => feeds.includes(item));
        //   }
        //   return true;
        // });
        return validComps || [];
      },
      set(value: Array<SectionComponent>) {
        // value is a list of the current components
        // however "currentSection" is a higher level object that comes from home.vue
        // we map over it and replace the components
        const section: LayoutSection = { ...this.currentSection };
        let custimazationsList;
        section?.components?.map((component: TopLevelSectionComponent) => {
          // validComponents {type} can be "default" or "summary". We know which it is from the validComponents object
          if (component.type === this.validComponents.type) {
            component.components = value;
          }
          custimazationsList = component.components?.map(c => {
            return { cid: c.cid, overrides: c.overrides, id: c.id };
          });
          return component;
        });
        this.$store.dispatch('layoutEditor/setCurrCustomizations', custimazationsList);
        this.$store.dispatch('layoutEditor/setEnableSave', true);
        this.$emit('set-current-section', section);
      },
    },
    isInFilledRow(): object {
      // this array defines if a comp is in a filled row or not
      // i.e, a full width comp or two half width comps
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isInFilledRow: any[] = [];
      this.componentsList.forEach(comp => {
        const id = { [comp.id]: true };
        isInFilledRow.push(id);
      });

      let i = 0;
      while (i < this.componentsList.length) {
        const comp = this.componentsList[i];
        const currIsHalf = comp.breakpoints?.includes('lg6');
        const currIsFull = comp.breakpoints?.includes('lg12');
        const nextIsHalf = this.componentsList[i + 1]?.breakpoints?.includes('lg6');
        let inc = 1;

        if (currIsFull || i === this.componentsList.length - 1) {
          if (isInFilledRow[comp.id]) isInFilledRow[comp.id] = true;
        } else if (currIsHalf && nextIsHalf) {
          if (isInFilledRow[comp.id]) isInFilledRow[comp.id] = true;
          if (isInFilledRow[this.componentsList[i + 1].id]) isInFilledRow[this.componentsList[i + 1].id] = true;
          inc = 2;
        } else {
          if (isInFilledRow[comp.id]) isInFilledRow[comp.id] = false;
        }

        i += inc;
      }
      return isInFilledRow;
    },
    shouldUsingTabs(): boolean {
      return this.currentSection?.shouldUsingTabs;
    },
    showDraggable(): boolean {
      // drag ability will be possible on more specific scope on summary page
      return this.$store.state.layoutEditor.editMode && this.$route.query?.tab !== 'summary';
    },
    onSummaryPage(): boolean {
      return this.$route.query?.tab === 'summary';
    },
    inEditMode(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
    isArchived() {
      if (this.$store.state?.filters?.selectedCampaigns?.length)
        return this.$store.state?.filters?.selectedCampaigns[0]?.Archived;
      return false;
    },
  },
  mounted() {
    this.getAgency();
    this.selectedTab = this.$route.query?.viewtab ? Number(this.$route.query?.viewtab) : 1;
  },
  methods: {
    trackMovingComponent(): void {
      this.analyticTrack(this.trackValue.EVENT_CONFIGURABILITY, this.trackValue.MOVE_MODULE);
    },
    setCurrentSection(section: CurrentSection): void {
      this.$emit('set-current-section', section);
    },
    checkMove(e) {
      return this.isDraggable(e.draggedContext);
    },
    isDraggable(context) {
      // DASH-5460: for broadcast - do not allow moving adv common modules above separator and regular modules below separator
      // TODO: if ever needed for another tactics - implement solution with new props in comp config
      if (!(this.$store.state.customer?.currentNavTab === 'broadcast')) return true;

      const { index, futureIndex } = context;
      const indexOfSectionSeparator = this.componentsList.findIndex(c => c.__typename === 'sectionHeader');

      if (index === indexOfSectionSeparator) return false;
      return !(
        (index < indexOfSectionSeparator && futureIndex >= indexOfSectionSeparator) ||
        (index > indexOfSectionSeparator && futureIndex <= indexOfSectionSeparator)
      );
    },
    async getAgency(): Promise<void> {
      const originId = this.$store.getters.user.analyticsAgencyId;
      if (originId) {
        await this.$store.dispatch('agencyAdmin/getAgency', { originId });
      }
    },
  },
});
