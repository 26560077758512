
import Vue from 'vue';
import MapComponent from '@point/map-component';
import EditModuleBtn from '../charts/buttons/editModuleBtn.vue';
import NoMapData from '../charts/no-data/NoMapData.vue';
import utils from '../../../util';
import debounce from 'lodash.debounce';

interface GeoData {
  name: string;
  Conversions: number;
  Impressions: number;
  pm: number;
  useDot?: boolean;
}

let unwatchDataChanges: () => void;

export default Vue.extend({
  inheritAttrs: false,
  name: 'conversionMap',
  components: { MapComponent, NoMapData, EditModuleBtn },
  props: {
    sectionConfig: Object,
    componentConfig: Object,
    title: String,
    theme: Object,
    isExporting: Boolean,
    isExportDynamic: Boolean,
    exportData: Object,
    exportContext: Object,
    componentHeight: Number,
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data(): any {
    return {
      geoPolygons: {},
      geoLookupByID: {},
      zipLookupByID: {},
      zipMaxRate: 0,
      geoData: null,
      loading: true,
      loadedValidData: true,
      hasRequiredData: false,
      hasRendered: false,
      mapReady: false,
      legend: false,
      dataCacheKey: null,
      layoutThemes: [],
      renderDots: true,
      hoveredGeo: null,
      dmaMarketsHash: {},
      useGeoList: false,
      hoveredDot: {
        impressions: 0,
        conversions: 0,
        pm: 0,
        pmClass: '',
      },
      fullyRendered: 0,
      exportBackgroundImage: null,
      mapId: null,
      renderMap: true,
      mapRefreshed: false,
      pmColors: [
        '#2e7d32',
        '#4caf50',
        '#8bc34a',
        '#cddc39',
        '#ffeb3b',
        '#ffc107',
        '#ff9800',
        '#ff5722',
        '#f44336',
        '#e53935',
        '#c62828',
      ],
      maxScore: 0,
      minScore: 0,
      pmTooltipTitle: 'PM',
      pmTooltipText:
        'stands for Performance Multiplier. It indicates how well or poorly locations (zip, city, DMA, state) perform relative to each other. PM is calculated as the the relative number of conversions tracked in the location divided by the relative number of impressions served there.',
    };
  },
  computed: {
    tactic(): string {
      return this.$route.query?.tab?.toUpperCase() || this.$store.state.customer?.selection?.tab?.toUpperCase();
    },
    isShared(): boolean {
      return this.$store.state.customer?.sharedDashboard;
    },
    geoListPerformancePayload(): any {
      // If params are not present in the url (like in shared view) find them in the store
      const id = this.$route.query?.id || this.$store.state.customer?.selection?.advertiserId || '';
      const daterange = this.$route.query?.daterange || this.$store.state.customer?.selection?.daterange || 'alltime';
      const startdate =
        this.$route.query?.campaignstartdate ||
        utils.formatDate(this.$store.state.customer?.selection?.startdate) ||
        '';
      const enddate =
        this.$route.query?.campaignenddate || utils.formatDate(this.$store.state.customer?.selection?.enddate) || '';
      const campaignsIds = this.$route.query?.viewCampaigns?.split(',');
      const type = this.$route.query?.tab?.toUpperCase() || this.$store.state.customer?.selection?.tab?.toUpperCase();

      const campaigns = campaignsIds?.map(c => {
        return {
          id: c,
          type: type,
        };
      });

      return {
        daterange,
        advertiserId: id,
        campaigns,
        startdate: startdate,
        enddate: enddate,
        isShared: this.isShared,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mergedCompConfig(): any {
      return { ...this.componentConfig, ...this.fallbackMap };
    },
    mapRenderCacheKey(): string {
      return `_attributionMap_${this.dataCacheKey}_light`;
    },
    mapCacheKey(): string {
      return `_attributionMap_${this.dataCacheKey}`;
    },

    mapTilerMapId(): string {
      const isPrinting = this.$route.query.print === 'true';
      if (isPrinting) {
        return utils.getMapId('printing');
      }
      const { theme } = this.$store.state.customer;
      if (this.layoutThemes?.length === 1) {
        // if only one theme exist
        return utils.getMapId(this.layoutThemes[0]);
      }
      if (this.layoutThemes?.length > 1) {
        // if dark and light exist
        const returnTheme = theme ? this.layoutThemes[1] : this.layoutThemes[0];
        return utils.getMapId(returnTheme);
      }
      return '24753aa3-7a2d-4bb6-9370-e7d657b08efb';
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    replacementDataSource(): string | boolean {
      return this.componentConfig?.fallbackMap?.dataSource;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fallbackMap(): any {
      return this.componentConfig?.fallbackMap;
    },
    inEditMode(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
    onlySummary(): boolean {
      return this.componentConfig.onlySummary;
    },
    onlyMap(): boolean {
      return this.componentConfig.onlyMap;
    },
  },
  mounted() {
    this.$store.dispatch('setFetching', { cid: this.componentConfig.cid });

    // only initMap when data is loaded, there may be old data from previous mount
    setTimeout(() => {
      if (!utils.isWaitingOnData(this)) {
        this.debouncedInitMap();
      }
    }, 1000);

    unwatchDataChanges = utils.fireOnAdDataChange(this, this.debouncedInitMap, true);
  },
  beforeDestroy(): void {
    unwatchDataChanges();
  },
  watch: {
    mapRenderCacheKey: {
      handler(key: string, old: string): void {
        if (old.indexOf('_null_') > 0) {
          return; // ignore the first mounted, before data is available
        }

        setTimeout(() => {
          this.renderAllGeos({ clear: false });
        }, 500);
      },
    },
    '$route.query.id': {
      handler(): void {
        this.loading = true;
      },
      immediate: false,
    },
    '$route.query.viewCampaigns': {
      handler(): void {
        this.debouncedInitMap();
      },
      immediate: false,
    },
  },
  methods: {
    debouncedInitMap: debounce(function (): void {
      this.initMap();
    }, 1000),
    async initMap() {
      if (this.onlySummary) {
        this.loading = false;
        return;
      }

      // when navigation across tactics, this map control gets recycled, not unmounted/remounted
      this.mapReady = false;
      this.geoData = [];
      this.geoPolygons = {};
      this.geoLookupByID = {};
      this.zipLookupByID = {};
      this.zipMaxRate = 0;
      this.hoveredGeo = null;
      this.loadedValidData = true;
      this.hasRequiredData = false;
      this.useGeoList = false;
      this.legend = false;
      this.dataCacheKey = null;
      this.renderDots = true;

      let dataSourceKey = this.componentConfig.dataSource;

      if (Array.isArray(this.componentConfig.dataSource) && this.$store.state.filters.selectedCampaigns) {
        // special case for GAM
        dataSourceKey = this.componentConfig.dataSource.find((source: string) => {
          return source === this.$store.state.filters.selectedCampaigns[0].CampaignType;
        });
      }

      let dataSource = '';
      let dataCheck: any; // eslint-disable-line @typescript-eslint/no-explicit-any

      if (this.isExporting) {
        dataCheck = this.exportData.adData[dataSourceKey]
          ? this.exportData.adData[dataSourceKey]
          : this.exportData.adData[this.replacementDataSource];
      } else {
        dataCheck = utils.adDataForKey(this, dataSourceKey);
      }

      if (!dataCheck && this.isExporting) {
        // console.error('no map data', dataSourceKey);
        setTimeout(() => {
          if (!this.hasRequiredData) {
            // do not tell fileexport the render failed if we are falling back to the genericMap
            if (!this.fallbackMap) {
              if (this.hasRendered) {
                return;
              }
              this.$emit('rendered', { empty: true, check: 'required data #1', dataSourceKey });
              this.hasRendered = true;
            }
          }
        }, 10);
        return;
      }

      let gData;
      if (this.isExporting) {
        gData = utils.adDataForKey(this, `${dataSourceKey}.GeoList`);
        this.loading = false;
      } else {
        gData = await this.getGeoListPerformance();
      }

      if (gData) {
        dataSource = `${dataSourceKey}.GeoList`;
        this.useGeoList = true;
      } else {
        gData = utils.adDataForKey(this, `${dataSourceKey}.DMAList`);
        dataSource = `${dataSourceKey}.DMAList`;
      }

      this.geoData = gData;
      const selectionKey =
        `${this.$route.query.view || ''} ${this.$route.query.id || ''} ` +
        `${this.$route.query.tab || ''} ${this.$route.query.viewCampaigns || ''} ` +
        `${this.$route.query.daterange || ''} ${this.$route.query.startdate || ''} ` +
        `${this.$route.query.enddate || ''}`;
      this.dataCacheKey = `${dataSource}_${selectionKey}`.trim().replace(/[\W_]+/g, '_');

      if (utils.isWaitingOnData(this)) {
        return;
      }
      // bug fix for only one unknown geo DASH-1943
      const gDataCheck = this.dataCacheKey && this.geoData && Array.isArray(this.geoData) && this.geoData.length > 0;
      let unknownOnly = false;
      if (gDataCheck) {
        unknownOnly = this.geoData.length === 1 && this.geoData[0]?.Type?.toLowerCase() === 'unknown';
      }

      this.hasRequiredData = gDataCheck && !unknownOnly;

      if (!this.hasRequiredData) {
        this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
        this.loading = false;
        setTimeout(() => {
          if (!this.onlySummary && this.hasRequiredData) {
            // handled by map
          } else if (this.fallbackMap && this.replacementDataSource) {
            // handled by map fallback
          } else if (!this.onlyMap) {
            // handled by map-campaign-summary-controller
          } else {
            if (this.hasRendered) {
              return;
            }
            this.$emit('rendered', { empty: true, check: 'required data #2' });
            this.hasRendered = true;
          }
        }, 10);
        return;
      }

      if (this.useGeoList) {
        const RSNMergedGeoData = [];
        const RSNMergedById = {};
        const RSNOuterMarkets = {};
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.geoData.forEach((geo: any) => {
          if (geo.Type !== 'OuterMarket') {
            return;
          }
          const marketKey = `${geo.name} | ${geo.League} | ${geo.Team}`;
          if (RSNOuterMarkets[marketKey]) {
            // eslint-disable-next-line no-console
            console.log('duplicate outer market', geo);
          }
          RSNOuterMarkets[marketKey] = geo;
        });

        // find outer markets first
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.geoData.forEach((geo: any) => {
          if (geo.Type === 'OuterMarket') {
            return;
          }
          // merge DMA polygons data for RSN entries (multiple teams per `DMA)
          if (geo.Type === 'DMA Region' && geo.GeoKey?.includes('_RSN_')) {
            if (!RSNMergedById[geo.GeoParamVal]) {
              RSNMergedById[geo.GeoParamVal] = {
                Impressions: -1,
                GeoKey: '',
                name: '',
                Type: '',
                Lat: geo.Lat,
                Long: geo.Long,
                GeoParamVal: geo.GeoParamVal,
                GeoParamKey: geo.GeoParamKey,
                legacy: [],
                teams: [],
                table: { headers: [], data: [] },
                outerMarket: null,
              };
            }
            const merged = RSNMergedById[geo.GeoParamVal];
            if (geo.League || geo.Team || geo.RSN) {
              const marketKey = `${geo.name} | ${geo.League} | ${geo.Team}`;
              const outerMarket = RSNOuterMarkets[marketKey];
              if (outerMarket) {
                merged.outerMarket = outerMarket;
              }
              merged.teams.push(geo);
            } else {
              merged.legacy.push(geo);
            }
            if (geo.Impressions > merged.Impressions) {
              RSNMergedById[geo.GeoParamVal] = { ...merged, ...geo };
            }
          } else {
            RSNMergedGeoData.push(geo);
          }
        });
        Object.keys(RSNMergedById).forEach((key: string) => {
          RSNMergedGeoData.push(RSNMergedById[key]);
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        RSNMergedGeoData.forEach((geo: any) => {
          if (geo.GeoKey && utils.isUnknownOrOtherOrInvalidString(geo.GeoKey)) {
            return;
          }

          const dup = this.geoLookupByID[geo.GeoParamVal] || {};
          this.geoLookupByID[geo.GeoParamVal] = { ...dup, ...geo };
        });
        this.loadAllGeos();
      }

      this.legend = this.componentConfig.legend;
      this.layoutThemes = this.componentConfig?.themes;
    },
    refreshMap(): void {
      if (this.mapRefreshed) return;
      this.renderMap = false;

      setTimeout(() => {
        this.renderMap = true;

        const map = this.$refs[this.mapCacheKey]?.Get();
        if (map) {
          const currentZoom = map.GetZoom();

          if (typeof currentZoom === 'number') {
            map.SetZoom(currentZoom - 1, { animate: false });
            this.drawBackground();

            setTimeout(() => {
              map.SetZoom(currentZoom, { animate: true });
              this.mapRefreshed = true;
            }, 1000);
          }
        }
      }, 500);
    },
    debounceRefreshMap: debounce(function (): void {
      this.refreshMap();
    }, 2000),
    async getGeoListPerformance(): Promise<void> {
      try {
        const { data } = await this.$store.dispatch(
          'performance/getGeoListPerformance',
          this.geoListPerformancePayload,
        );

        if (data) {
          return data[this.componentConfig.dataSource];
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    hijackRendered(): void {
      setTimeout(() => {
        if (this.hasRendered) {
          return;
        }
        this.$emit('rendered', { empty: false });
        this.hasRendered = true;
      }, 10);
    },
    drawBackground() {
      const mapEl = this.$refs[this.mapId]?.$el;
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map) {
        // eslint-disable-next-line no-console
        console.error('attributionMap, can not export, no map');
        return;
      }
      const zoom = Math.round(map.GetZoom()) - 1;

      const center = map.GetCenter();
      // const bounds = map.GetBounds();
      // const staticMapByBounds = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
      const staticMapByCenter = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${center.lng},${center.lat},${zoom}/${mapEl?.offsetWidth}x${mapEl?.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
      var img = new Image();
      img.addEventListener(
        'load',
        () => {
          this.exportBackgroundImage = staticMapByCenter;
          // tileBackground.style.display = 'none';
          setTimeout(() => {
            this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
            if (this.hasRendered) {
              return;
            }
            this.$emit('rendered', { empty: false });
            this.hasRendered = true;
          }, 1000);
        },
        false,
      );
      img.src = staticMapByCenter;
      this.fullyRendered = 2;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emitRendered(doneRendering: boolean, map: any, mapId: string, retries = 5): void {
      if (this.hasRendered) {
        return;
      }
      // console.log('doneRendering', doneRendering, retries);
      if (doneRendering && this.fullyRendered === 0) {
        this.fullyRendered = 1;
      }
      if (this.fullyRendered === 1) {
        if (this.isExporting) {
          setTimeout(() => {
            const mapEl = this.$refs[mapId]?.$el;
            if (!mapEl) {
              // eslint-disable-next-line no-console
              console.error('attributionMap, can not export, no map element');
              if (retries > 0) {
                setTimeout(() => {
                  this.emitRendered(doneRendering, map, mapId, retries - 1);
                }, 500);
              } else {
                setTimeout(() => {
                  if (this.hasRendered) {
                    return;
                  }
                  this.$emit('rendered', { empty: true, check: 'map element' });
                  this.hasRendered = true;
                }, 10);
              }
              return;
            }
            const tileBackgroundList = mapEl.getElementsByClassName('mapboxgl-map');
            if (!tileBackgroundList || tileBackgroundList.length === 0) {
              // eslint-disable-next-line no-console
              console.log('attributionMap, waiting for map background...', retries);
              if (retries > 0) {
                setTimeout(() => {
                  this.emitRendered(doneRendering, map, mapId, retries - 1);
                }, 500);
              } else {
                // eslint-disable-next-line no-console
                console.error('attributionMap, can not export, no map background', retries);
                setTimeout(() => {
                  if (this.hasRendered) {
                    return;
                  }
                  this.$emit('rendered', { empty: true, check: 'map background' });
                  this.hasRendered = true;
                }, 10);
              }
              return;
            }
            const tileBackground = tileBackgroundList[0] as HTMLElement;
            if (!map) {
              map = this.$refs[this.mapCacheKey]?.Get();
            }
            if (!map) {
              // eslint-disable-next-line no-console
              console.error('attributionMap, can not export, no map', retries);
              return;
            }
            const zoom = Math.round(map.GetZoom()) - 1;
            const center = map.GetCenter();
            // const bounds = map.GetBounds();
            // const staticMapByBounds = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
            const staticMapByCenter = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${center.lng},${center.lat},${zoom}/${mapEl?.offsetWidth}x${mapEl?.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
            var img = new Image();
            img.addEventListener(
              'load',
              () => {
                this.exportBackgroundImage = staticMapByCenter;
                tileBackground.style.display = 'none';
                setTimeout(() => {
                  this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
                  if (this.hasRendered) {
                    return;
                  }
                  this.$emit('rendered', { empty: false });
                  this.hasRendered = true;
                }, 1000);
              },
              false,
            );
            img.src = staticMapByCenter;
            this.fullyRendered = 2;
          }, 500);
        } else {
          setTimeout(() => {
            if (this.hasRendered) {
              return;
            }
            this.$emit('rendered', { empty: false });
            this.hasRendered = true;
          }, 100);
        }
      }
    },
    onMapReady(evt): void {
      const { id, redrawing } = evt;
      const map = this.$refs[id]?.Get();
      this.mapId = id;
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }
      if (!redrawing) {
        map.ZoomControlsOnRight();
      }
      this.mapReady = true;
      if (this.mapRenderCacheKey !== map.renderCacheKey) {
        setTimeout(() => {
          map.Redraw();
        }, 20);
        setTimeout(() => {
          this.renderAllGeos();
        }, 500);
      }
      const mapDiv = document.getElementById('attributionMap');
      const resizeObserver = new ResizeObserver(() => {
        map.Resize();
      });

      resizeObserver.observe(mapDiv);
    },
    debounceMapMoved(): void {
      clearTimeout(this.onMapMovedTimer);
      this.drawBackground();
      this.onMapMovedTimer = setTimeout(() => {
        const map = this.$refs[this.mapCacheKey]?.Get();
        if (!map || !map.host || !map.leaflet) {
          return;
        }
        const zoomLevel = map.GetZoom();
        const newVal = !(zoomLevel && zoomLevel > 9);
        if (newVal !== this.renderDots) {
          this.renderDots = newVal;
          if (this.useGeoList) {
            this.renderAllGeos();
          }
        }
      }, 500);
    },
    loadAllGeos(): void {
      if (this.geoData && Array.isArray(this.geoData) && this.geoData.length > 0) {
        this.loading = true;
      } else {
        // eslint-disable-next-line no-console
        console.error('loadAllGeos empty');
        return;
      }

      // see if we already have lat-long
      if (this.geoData[0].Lat && this.geoData[0].Long) {
        this.loading = false;
        this.renderDots = true;
        this.renderAllGeos({ clear: true });
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const geoKeys: any = {};
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.geoData.forEach((geo: any) => {
        // ignore 'other' zip codes
        if (geo.Zipcode && utils.isUnknownOrOtherOrInvalidString(geo.Zipcode)) {
          return;
        }

        if (!geo.GeoParamKey) {
          if (!this.isExporting) {
            // eslint-disable-next-line no-console
            console.error('geoList without a key', geo);
          }
          return;
        }
        if (!geo.GeoParamVal) {
          if (!this.isExporting) {
            // eslint-disable-next-line no-console
            console.error('geoList without a name', geo);
          }
          return;
        }
        if (!geoKeys[geo.GeoParamKey]) {
          geoKeys[geo.GeoParamKey] = [];
        }
        if (geoKeys[geo.GeoParamKey].length < 500 && !geoKeys[geo.GeoParamKey].includes(geo.GeoParamVal)) {
          geoKeys[geo.GeoParamKey].push(geo.GeoParamVal);
        }
      });
    },
    renderAllGeos(
      { clear, redrawing, adjustView, retries, doneRendering } = {
        clear: false,
        redrawing: false,
        adjustView: false,
        retries: 100,
        doneRendering: false,
      },
    ): void {
      if (!this.mapReady || this.loading) {
        setTimeout(() => {
          this.renderAllGeos({ clear, redrawing, adjustView, retries: retries - 1, doneRendering });
        }, 250);
        return;
      }

      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map || !map.host || !map.leaflet) {
        if (retries < 0) {
          // eslint-disable-next-line no-console
          console.error('map component did not load correctly', map, this.mapCacheKey);
          return;
        }
        setTimeout(() => {
          this.renderAllGeos({ clear, redrawing, adjustView, retries: retries - 1, doneRendering });
        }, 250);
        return;
      }

      this.emitRendered(doneRendering, map, this.mapCacheKey);

      map.ClearMap();

      let polygonKeyList = Object.keys(this.geoLookupByID);
      let maxPolygonLimit = 100;
      if (this.componentConfig.layerLimit && this.componentConfig.layerLimit > 0) {
        maxPolygonLimit = this.componentConfig.layerLimit;
      }

      let hideZeros = !!this.componentConfig.hideZeros;
      let useDots = this.renderDots;

      const zoomLevel = map.GetZoom();
      if (zoomLevel && zoomLevel > 9) {
        useDots = false;
        hideZeros = false;
      }

      if (polygonKeyList.length > 0 && polygonKeyList.length <= 50) {
        useDots = false;
      }

      // set max polygon to 1000 to release DASH-5123 before big map refactor DASH-5059
      maxPolygonLimit = 1000;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let topScoringGeos: any[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const topScoringLayers: any[] = [];

      let totalConversions = 0;
      let totalImpressions = 0;

      let sorted: any[] = [];
      Object.keys(this.geoLookupByID).forEach((k: string) => {
        const geoData = this.geoLookupByID[k] as GeoData;

        // Calculate campaign totals
        totalConversions = Object.values(this.geoLookupByID).reduce(
          (acc: number, geo: GeoData) => acc + (geo.Conversions || 0),
          0,
        ) as number;

        totalImpressions = Object.values(this.geoLookupByID).reduce(
          (acc: number, geo: GeoData) => acc + (geo.Impressions || 0),
          0,
        ) as number;

        // Calculate PM using the new formula
        const conversions = geoData.Conversions || 0;
        const impressions = geoData.Impressions || 0;

        // PM_l = (Con_l / Con_c) / (Imp_l / Imp_c)
        const conversionRatio = totalConversions > 0 ? conversions / totalConversions : 0;
        const impressionRatio = totalImpressions > 0 ? impressions / totalImpressions : 0;

        geoData.pm = conversionRatio / impressionRatio;
        geoData.useDot = true;

        sorted.push({ s: geoData.pm, k });
      });
      sorted = sorted.sort((a, b) => (a.s > b.s ? 1 : b.s > a.s ? -1 : 0));
      polygonKeyList = sorted.map(kv => kv.k);

      if (polygonKeyList.length > maxPolygonLimit) {
        polygonKeyList = polygonKeyList.slice(-1 * maxPolygonLimit);
        if (this.componentConfig.legend) {
          this.legend = this.componentConfig.legend + ` (top ${maxPolygonLimit})`;
        }
      } else if (this.componentConfig.legend) {
        this.legend = this.componentConfig.legend;
      }

      this.hasRequiredData = totalConversions > 0;
      this.calculateScoreRange();

      const geosLayer = map.CreateLayer('GEOs');
      let hasPolygons = false;

      polygonKeyList.forEach((key: string) => {
        let tooltipFollowsCursor = false;
        let persistentTooltip = false;
        const geoData = this.geoLookupByID[key];
        const views = geoData.Impressions || geoData.PageViews;
        if (!geoData || (hideZeros && !views)) {
          return;
        }

        let layer, style, hoverStyle, color;
        if (geoData.useDot) {
          // console.log('rendering dots', key);
          const lat = parseFloat(geoData.Lat);
          const lon = parseFloat(geoData.Long);
          if (isNaN(lat) || isNaN(lon) || lat === 0 || lon === 0) {
            // console.log('geoData no lat/lon', geoData, lat, lon);
            return;
          }

          const divIcon = { className: 'fenceIcon', zIndexOffset: geoData.score };

          layer = map.AddDivToLayer(divIcon, lat, lon, geosLayer);

          color = this.getColorByScore(geoData.pm, this.minScore, this.maxScore);
          if (layer._icon) {
            layer._icon.style.background = color;
          } else {
            setTimeout(() => {
              if (layer._icon) {
                layer._icon.style.background = color;
              }
            }, 200);
          }
        }

        if (!layer) {
          // eslint-disable-next-line no-console
          console.error('failed to create map layer');
          return;
        }

        if (topScoringGeos.includes(key)) {
          topScoringLayers.push(layer);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let layerPopup: any = null;
        hasPolygons = true;
        let hovered = false;
        layer
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mouseover', (e: any) => {
            this.hoveredGeo = geoData;
            if (!layer) {
              return;
            }
            if (layer._icon) {
              // layer._icon.style.opacity = 1;
            } else if (layer.setStyle) {
              layer.setStyle(hoverStyle);
            }

            hovered = true;

            let popupWidth;

            this.hoveredDot.impressions = geoData.Impressions;
            this.hoveredDot.conversions = geoData.Conversions;
            this.hoveredDot.pm = geoData.pm?.toFixed(2);

            const find_color = this.getColorByScore(geoData.pm, this.minScore, this.maxScore);
            const find_index = this.pmColors.findIndex(color => color === find_color);

            this.hoveredDot.pmClass = `pm-${find_index}`;

            let latlon;
            if (tooltipFollowsCursor) {
              latlon = e.latlng;
            }
            if (!latlon || geoData.useDot) {
              // use the dot's center
              const lat = parseFloat(geoData.Lat);
              const lon = parseFloat(geoData.Long);
              if (!isNaN(lat) && !isNaN(lon)) {
                latlon = [lat, lon];
              }
            }
            if (!latlon) {
              // eslint-disable-next-line no-console
              console.log('creating popup without lat/lon', geoData);
              // return;
            }
            setTimeout(() => {
              if (!hovered) {
                return;
              }
              const content = this.$refs.rsnToolTip || this.$refs.mapToolTip;
              if (!content) {
                layerPopup = null;
                return;
              }
              layerPopup = map.CreatePopup(content, latlon, {
                closeButton: false,
                offset: [2, 10],
                minWidth: popupWidth,
                // bubblingMouseEvents: !persistentTooltip, does not work :(
              });
              const popupEl = layerPopup._wrapper; // this.$refs.rsnToolTip;
              popupEl.style.pointerEvents = 'none';
              if (persistentTooltip) {
                popupEl.style.pointerEvents = 'all';
                // console.log({ popupEl, layerPopup });
                popupEl.addEventListener(
                  'mousemove',
                  e => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    hovered = true;
                  },
                  true,
                );
                popupEl.addEventListener(
                  'mouseover',
                  e => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    hovered = true;
                  },
                  true,
                );
                popupEl.addEventListener(
                  'mouseout',
                  e => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    hovered = false;
                    setTimeout(() => {
                      if (!hovered) {
                        map.ClosePopup(layerPopup);
                        layerPopup = null;
                      }
                    }, 250);
                  },
                  true,
                );
              }
            }, 10);
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mousemove', (e: any) => {
            if (tooltipFollowsCursor && !geoData.useDot && layerPopup) {
              layerPopup.setLatLng(e.latlng);
            }
          })
          .on('mouseout', () => {
            if (!layer) {
              return;
            }
            hovered = false;
            setTimeout(() => {
              if (layer._icon) {
              } else if (layer.setStyle) {
                layer.setStyle(style);
              }
              if (!persistentTooltip && layerPopup) {
                map.ClosePopup(layerPopup);
                layerPopup = null;
              }
            }, 50);
          });
      });

      map.SetRenderCacheKey(this.mapRenderCacheKey);
      if ((map.initialized && clear && !adjustView) || !hasPolygons) {
        return;
      }

      let paddingOptions = { paddingTopLeft: [20, 20], paddingBottomRight: [20, 20] };
      if (!this.onlyMap) {
        paddingOptions = { ...paddingOptions, paddingTopLeft: [400, 20] };
      }

      if (!map.initialized) {
        setTimeout(() => {
          if (topScoringLayers.length > 1) {
            map.FlyToLayers(topScoringLayers, { force: true, animate: false, ...paddingOptions });
          } else {
            map.FitAllLayers({ force: true, animate: false, ...paddingOptions });
          }
        }, 10);
        return;
      }

      this.debounceRefreshMap();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    teamHovered(geo: any): void {
      const homeLayer = this.dmaMarketsHash[geo.key];
      const outermarketLayer =
        this.dmaMarketsHash[geo.key.replace('DMA_Region_Optimum_Sports', 'Outermarket_Optimum_Sports')];
      if (homeLayer) {
        homeLayer.setStyle(this.Theme?.polygons?.light?.rsnHover);
      }
      if (outermarketLayer) {
        outermarketLayer.setStyle(this.Theme?.polygons?.light?.outerMarketHover);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    teamHoveredOut(geo: any): void {
      const homeLayer = this.dmaMarketsHash[geo.key];
      const outermarketLayer =
        this.dmaMarketsHash[geo.key.replace('DMA_Region_Optimum_Sports', 'Outermarket_Optimum_Sports')];
      if (homeLayer) {
        homeLayer.setStyle(this.Theme?.polygons?.light?.rsn);
      }
      if (outermarketLayer) {
        outermarketLayer.setStyle(this.Theme?.polygons?.light?.outerMarket);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rendered(ctx: any): void {
      if (this.hasRendered) {
        return;
      }
      this.$emit('rendered', ctx);
      this.hasRendered = true;
    },
    getColorByScore(score: number, min: number, max: number): string {
      const minNum = typeof min === 'string' ? parseFloat(min) : min;
      const maxNum = typeof max === 'string' ? parseFloat(max) : max;

      const ranges = Array.from({ length: 11 }, (_, i) => {
        const rangeSize = (maxNum - minNum) / 11;
        const rangeMin = minNum + rangeSize * i;
        const rangeMax = minNum + rangeSize * (i + 1);
        return { min: rangeMin, max: rangeMax };
      });

      const colorIndex = ranges.findIndex(range => score >= range.min && score <= range.max);
      return this.pmColors[colorIndex === -1 ? 0 : 10 - colorIndex];
    },
    calculateScoreRange() {
      const maxPm = Math.max(...Object.values(this.geoLookupByID)?.map((geo: any) => geo.pm));
      const minPm = Math.min(...Object.values(this.geoLookupByID)?.map((geo: any) => geo.pm));

      // If max and min are the same and more than 0, set min to 0
      if (maxPm === minPm && maxPm > 0) {
        this.maxScore = maxPm.toFixed(2);
        this.minScore = (0).toFixed(2);
      } else {
        this.maxScore = maxPm.toFixed(2);
        this.minScore = minPm.toFixed(2);
      }

      // find object with max pm
      const maxPmObject = Object.values(this.geoLookupByID).find((geo: any) => geo.pm === maxPm);
      console.log('max limit object', {
        conversions: maxPmObject.Conversions,
        impressions: maxPmObject.Impressions,
        pm: maxPmObject.pm,
      });

      // find object with min pm
      const minPmObject = Object.values(this.geoLookupByID).find((geo: any) => geo.pm === minPm);
      console.log('min limit object', {
        conversions: minPmObject.Conversions,
        impressions: minPmObject.Impressions,
        pm: minPmObject.pm,
      });

      const totalConversions = Object.values(this.geoLookupByID).reduce(
        (acc: number, geo: any) => acc + (geo.Conversions || 0),
        0,
      ) as number;

      const totalImpressions = Object.values(this.geoLookupByID).reduce(
        (acc: number, geo: any) => acc + (geo.Impressions || 0),
        0,
      ) as number;

      console.log('total conversions', totalConversions);
      console.log('total impressions', totalImpressions);
    },
  },
});
