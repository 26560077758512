import { render, staticRenderFns } from "./publisher-image.vue?vue&type=template&id=648e99c5&scoped=true"
import script from "./publisher-image.vue?vue&type=script&lang=ts"
export * from "./publisher-image.vue?vue&type=script&lang=ts"
import style0 from "./publisher-image.vue?vue&type=style&index=0&id=648e99c5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "648e99c5",
  null
  
)

export default component.exports