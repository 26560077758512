
import Vue from 'vue';
import debounce from 'lodash/debounce';
import { C360Icon } from '@c360/ui';
import analytics from '../../../../mixins/analytics';

const NUMBER_TO_START_SEARCHING = 3;

export default Vue.extend({
  name: 'DropdownFilter',
  components: {
    C360Icon,
  },
  data(): {
    dropdown: boolean;
    tab: number;
    searchAdvertiser: string;
    searchUser: string;
    advertisers: Record<string, string>[];
    users: Record<string, string>[];
    selectedAdvertisers: Record<string, string>[];
    selectedUser: Record<string, string> | null;
    loadingAdvertisers: boolean;
    loadingUsers: boolean;
    loadingFromLayout: boolean;
    hoveredAdvertiser: string | null;
    tabs: string[];
  } {
    return {
      dropdown: false,
      tab: 0,
      searchAdvertiser: '',
      searchUser: '',
      advertisers: [],
      users: [],
      selectedAdvertisers: [],
      selectedUser: null,
      loadingAdvertisers: false,
      loadingUsers: false,
      loadingFromLayout: false,
      hoveredAdvertiser: null,
      tabs: ['FILTER BY ADVERTISER', 'FILTER BY USER'],
    };
  },
  mixins: [analytics] as any,

  created() {
    this.firstLoadFetchData();
  },
  computed: {
    error() {
      return this.$store.state.linearAdmin.linearAdminError || this.$store.state.primary.filterError;
    },
    loading() {
      return this.loadingAdvertisers || this.loadingUsers || this.loadingFromLayout;
    },
  },
  methods: {
    isHoveredAdvertiser(id: string) {
      return this.hoveredAdvertiser === id;
    },
    toggleHoverAdvertiser(id: string, isHovering: boolean) {
      this.hoveredAdvertiser = isHovering ? id : null;
    },
    async firstLoadFetchData() {
      try {
        this.loadingFromLayout = true;
        await Promise.all([this.fetchAdvertisers(), this.fetchUsers()]);
        this.loadSelectedItems();
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.loadingFromLayout = false;
      }
    },
    onSearchInput(type) {
      if (type === 'advertiser' && this.searchAdvertiser?.length > NUMBER_TO_START_SEARCHING) {
        this.debounceFetchAdvertisers();
      } else if (type === 'user' && this.searchUser?.length > NUMBER_TO_START_SEARCHING) {
        this.debounceFetchUsers();
      }
    },
    clearSearch(type) {
      if (type === 'advertiser') {
        this.searchAdvertiser = '';
        this.debounceFetchAdvertisers();
      } else if (type === 'user') {
        this.searchUser = '';
        this.debounceFetchUsers();
      }
    },
    debounceFetchAdvertisers: debounce(function () {
      this.fetchAdvertisers();
    }, 1000),
    debounceFetchUsers: debounce(function () {
      this.fetchUsers();
    }, 1000),
    async fetchAdvertisers(): Promise<void> {
      if (this.loadingAdvertisers) return;
      this.loadingAdvertisers = true;
      try {
        await this.$store.dispatch('linearAdmin/getLinearAdminClientsSearch', {
          limit: 100,
          name: this.searchAdvertiser,
        });
        this.advertisers = this.$store.state.linearAdmin.linearAdminClients.advertisers;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingAdvertisers = false;
      }
    },
    async fetchUsers() {
      if (this.loadingUsers) return;
      this.loadingUsers = true;
      try {
        const users = await this.$store.dispatch('primary/getUsersByTenancy', {
          limit: 100,
          agency: this.$store.state.customer?.user?.agency_id,
          search: this.searchUser,
        });
        this.users = users;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingUsers = false;
      }
    },
    removeAdvertiser(advertiser) {
      if (this.loadingAdvertisers || this.loadingUsers || !!this.error) return;
      const index = this.selectedAdvertisers.findIndex(item => item.id === advertiser.id);
      if (index !== -1) {
        this.selectedAdvertisers.splice(index, 1);
      }
    },
    removeUser() {
      if (this.loadingAdvertisers || this.loadingUsers || !!this.error) return;
      this.selectedUser = null;
    },
    selectAdvertiser(advertiser) {
      if (this.loadingAdvertisers || this.loadingUsers) return;
      const index = this.selectedAdvertisers.findIndex(item => item.id === advertiser.id);
      if (index !== -1) {
        this.selectedAdvertisers.splice(index, 1);
      } else {
        this.selectedAdvertisers.unshift(advertiser);
      }
      this.selectedUser = null;
    },
    selectUser(user) {
      if (this.loadingAdvertisers || this.loadingUsers) return;
      this.selectedUser = user;
      this.selectedAdvertisers = [];
    },
    applySelection() {
      const userLayout = this.selectedUser ? this.selectedUser.email : null;
      const selectedUser = this.selectedUser
        ? { email: this.selectedUser.email, full_name: this.selectedUser.full_name }
        : null;
      const advertisersList = this.selectedAdvertisers.map(advertiser => advertiser.id);
      const selectedAdvertisers = this.selectedAdvertisers.map(advertiser => {
        return { id: advertiser.id, name: advertiser.name };
      });

      this.dropdown = false;
      if (selectedAdvertisers?.length > 0) {
        this.analyticTrack(
          this.trackValue.POST_LOGIN,
          `${this.trackValue.CHANGE_FILTER_BY}: Apply Filters: SelectedAdvertisers - ${JSON.stringify(
            selectedAdvertisers,
          )}: selectedUser - ${JSON.stringify(selectedUser)}`,
        );
      } else if (selectedUser) {
        this.analyticTrack(
          this.trackValue.POST_LOGIN,
          `${this.trackValue.CHANGE_FILTER_BY}: Apply Filters: SelectedUser - ${selectedUser?.email}`,
        );
      }
      this.$emit('apply-filtering', advertisersList, userLayout, selectedAdvertisers, selectedUser);
    },
    resetSelection() {
      this.selectedAdvertisers = [];
      this.selectedUser = null;
      this.searchAdvertiser = '';
      this.searchUser = '';
      this.analyticTrack(this.trackValue.POST_LOGIN, `${this.trackValue.CHANGE_FILTER_BY}: Clear Filters`);
    },
    toggleDropdown() {
      this.dropdown = !this.dropdown;
    },
    isAdvertiserSelected(advertiser) {
      return this.selectedAdvertisers.some(ad => ad.id === advertiser.id);
    },
    isUserSelected(user) {
      return this.selectedUser && this.selectedUser.email === user.email;
    },
    loadSelectedItems() {
      const savedAdvertisers = this.$store.state.customer.dynamicLayout.layoutCustomizations.config.selectedAdvertisers;
      const savedUser = this.$store.state.customer.dynamicLayout.layoutCustomizations.config.selectedUser;

      if (savedAdvertisers?.length > 0 && this.advertisers?.length && !this.loadingAdvertisers) {
        this.selectedAdvertisers = savedAdvertisers;
        const advertiserIds = this.advertisers?.map(ad => ad.id);
        const missingAdvertisers = savedAdvertisers?.filter(ad => !advertiserIds.includes(ad.id));
        this.advertisers = [...missingAdvertisers, ...this.advertisers];
      } else if (savedUser && !this.loadingUsers && this.users?.length) {
        this.selectedUser = savedUser;
      } else {
        console.log('no saved items');
      }
    },
  },
});
