
import Vue from 'vue';
import NoChartData from '../no-data/NoChartData.vue';
import utils from '../../../../util';
import CreativePreview from './_creativePreview.vue';
import ProgressBarValues from '../../../../types/progressBarValues';
import ProgressBar from '../../progressBars/progressBar.vue';
import ExportDownloadBtn from '../buttons/exportDownloadBtn.vue';
import tableHeaderC360 from './tableHeaderC360.vue';
import { C360Icon, getCssVar } from '@c360/ui';

/*
todo:
topOptions -> if we want a menu in the top right
parsevalues

headers:
array of { text: "col title", align: "left", sortable: true, value: "data property name" }
*/
// function getBaseLog(x: number, y: number): number {
//   return Math.log(y) / Math.log(x);
// }

let unwatchDataChanges: () => void;

export default Vue.extend({
  inheritAttrs: false,
  name: 'progressBarTable',
  components: {
    CreativePreview,
    NoChartData,
    ProgressBar,
    ExportDownloadBtn,
    tableHeaderC360,
    C360Icon,
  },
  props: [
    'sectionConfig',
    'componentConfig',
    'title',
    'topOptions',
    'headers',
    'commonTweaks',
    'dataSource',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'componentHeight',
  ],
  data: (): {
    search: string;
    filterNumber: number;
    categorySelected: string;
    categories: Array<string>;
    gtltSelected: string;
    gtlt: Array<string>;
    renderKey: Array<number>;
    tableOptions: {
      page: number;
      sortBy: string[];
      sortDesc: boolean[];
      groupBy: string[];
      groupDesc: boolean[];
      multiSort: boolean;
      mustSort: boolean;
    };
    progressBarFontValues: {
      color: string;
      size: string;
      weight: string;
    };
    selectedTacticFilter: string | null;
    sortByMenuOpen: boolean;
    filterByTactic: boolean;
    tooltips: Array<string>;
    currKeyIndex: number;
    searchOpen: boolean;
    renderEventTriggered: boolean;
  } => ({
    search: '',
    filterNumber: 0,
    categorySelected: '',
    categories: [],
    gtltSelected: 'greater',
    gtlt: ['greater', 'less'],
    renderKey: [5],
    tableOptions: {
      page: 0,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    progressBarFontValues: {
      color: 'null',
      size: 'h5',
      weight: 'bold',
    },
    selectedTacticFilter: null,
    sortByMenuOpen: false,
    filterByTactic: false,
    tooltips: [],
    currKeyIndex: 0,
    searchOpen: false,
    renderEventTriggered: false,
  }),
  computed: {
    filteredOutTooltips(): any {
      let tips = this.componentConfig?.tooltips || [];
      const allZeroConversions = this.limitedData.every(data => data.Conversions === 0);
      if (allZeroConversions) {
        tips = tips.filter(tip => !['Conversions', 'Conversion Rate'].includes(tip.title));
      }
      const hasResponseShareData = this.limitedData?.some(data => data?.ResponseShare);
      if (!hasResponseShareData) {
        tips = tips.filter(tip => tip.title !== 'Response Share');
      }
      const hasNoVisitsData = this.limitedData?.every(data => data?.Visits === 0);
      if (hasNoVisitsData) {
        tips = tips.filter(tip => tip.title !== 'Visits');
      }
      if (!this.hasCreativeSpendData) {
        tips = tips.filter(tip => tip.title !== 'Cost');
      }
      if (this.isYahoo) {
        tips = tips.filter(tip => tip.title !== 'VCR (Video Completion Rate)');
      }
      if (this.hasAdditionalData) return tips;
      if (!this.componentConfig?.tooltips?.length) return [];
      tips = tips.filter(tooltip => !['Responses', 'ResponseRate', 'Index'].includes(tooltip?.title));
      return tips;
    },
    loading(): boolean {
      return utils.isWaitingOnData(this);
    },
    additionalMetrics(): [string] {
      return this?.componentConfig?.additionalMetrics || [];
    },
    hasAdditionalData(): boolean {
      return this?.componentConfig?.additionalMetrics && this?.rawData?.some(row => row?.isEnriched);
    },
    tableTitle(): string {
      if (this.isExporting && this.componentConfig.multiTable) {
        let total = Math.ceil(this.filteredData.length / this.itemsPerPage);
        if (this.componentConfig.maxPages && this.componentConfig.maxPages < total) {
          total = this.componentConfig.maxPages;
        }
        return this.$props.title + ' ' + (this.tableOptions.page + 1) + '/' + total;
      }
      return this.$props.title;
    },
    canExport(): boolean {
      if (this.$store.state.customer.currentSection?.xlsExportLocalOnly) {
        if (!utils.isLocalDev()) {
          return false;
        }
      }
      if (this.$store.state.customer.currentSection?.xlsExportDevOnly) {
        if (!utils.isLocalDev() && !utils.isDevelopment()) {
          return false;
        }
      }
      return this.tableData && this.tableData.length && (this.canExportToCSV || this.canExportToXLS);
    },
    canExportToCSV(): boolean {
      return !!this.componentConfig?.downloadTableData;
    },
    canExportToXLS(): boolean {
      return !!this.componentConfig?.exportableTableData;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
    itemsPerPage(): number {
      const maxNumberOfMetricsInRow = 5;
      if (this.isXLS) {
        return 10000;
      }
      if (this.componentConfig.cid === 'googlevideoPerformanceByCreativeProgressBarTable' && !this.isExporting) {
        return this.singleColumn ? 2 : 4;
      }
      if (typeof this.componentConfig.perPage === 'number') {
        return this.componentConfig.perPage;
      }
      if (this.isPublisherImage) {
        return this.singleColumn ? 6 : 18;
      }
      if (this.singleColumn) {
        return this.bars.length > maxNumberOfMetricsInRow ? 2 : 3;
      }
      return this.bars.length > maxNumberOfMetricsInRow ? 4 : 6;
    },
    containerCSS(): object {
      const css: { 'has-search'?: boolean } = {};
      if (this.componentConfig.searchEnabled) {
        css['has-search'] = true;
      }
      return css;
    },
    feedSources(): Array<string> {
      const feeds = utils.feedSources(this);
      return feeds;
    },
    filterByValueColumns(): string[] {
      if (this.componentConfig.columns) {
        let cols = this.componentConfig.columns;
        if (!this.hasAdditionalData) {
          cols = utils.filterOutColumns(cols, this.additionalMetrics);
        }
        // Certain columns are used for display, but not for sorting
        cols = utils.filterOutColumns(cols, ['Publisher']);
        if (!this.feedSources.includes('SIMPLIFI')) {
          cols = utils.filterOutColumns(cols, ['Actions']);
        }
        if (this.hideSpend) {
          // cols = cols.filter((c: string) => c !== 'CostPerClick');
          cols = utils.filterOutColumns(cols, ['CostPerClick']);
        }
        if (!this.hasCreativeSpendData) {
          cols = utils.filterOutColumns(cols, ['CostPerCreative']);
        }
        if (this.isCreativeTactic && !this.isExporting) {
          // cols = cols.filter((c: string) => c !== 'Tactic');
          cols = utils.filterOutColumns(cols, ['Tactic']);
        }

        // hide Clicks and CTR for GAM when GAM type is Video
        if (this.componentConfig.dataChecks === 'HasGAMVIDEO') {
          cols = utils.filterOutColumns(cols, ['Clicks', 'ClickThrough']);
        }

        // Hide VCR and VC100 if we have no/empty data for them
        if (!this.filteredEmptyMetrics.includes('VCR')) {
          cols = utils.filterOutColumns(cols, ['VCR']);
        }
        if (!this.filteredEmptyMetrics.includes('VC100')) {
          cols = utils.filterOutColumns(cols, ['VC100']);
        }

        if (
          this.componentConfig.cid === 'googlevideoPerformanceByCreativeProgressBarTable' &&
          this.filteredEmptyMetrics.length
        ) {
          const metricsWithoutData = this.componentConfig.availableBars.filter(
            metric => !this.filteredEmptyMetrics.includes(metric),
          );
          for (const emptyMetric of metricsWithoutData) {
            cols = utils.filterOutColumns(cols, [emptyMetric]);
          }
        }

        return cols;
      }
      return [];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tableData(): any {
      let rows = utils.adDataForKey(this, this.dataSource);
      if (!rows || !Array.isArray(rows)) {
        return [];
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rows = rows.map((x: any) => ({ ...x })); // shallow clone, for transforms to stay localized to this copy of the data
      if (this.componentConfig.columns) {
        let columns = this.componentConfig.columns;
        if (this.hideSpend) {
          columns = utils.filterOutColumns(columns, ['CostPerClick']);
        }
        if (!this.hasCreativeSpendData) {
          columns = utils.filterOutColumns(columns, ['CostPerCreative']);
        }

        // hide Clicks and CTR for GAM when GAM type is Video
        if (this.componentConfig.dataChecks === 'HasGAMVIDEO') {
          columns = utils.filterOutColumns(columns, ['Clicks', 'ClickThrough']);
        }

        // remove columns that aren't in the columns array
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rows = rows.map((x: any) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const c: any = {};
          columns.forEach((col: string) => {
            c[col] = x[col];
          });
          return c;
        });
      }
      // filter out, for example, aired < 2
      if (this.componentConfig.filter) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rows = rows.filter((i: any) => {
          if (this.gtltSelected === 'greater') {
            return i[this.categorySelected.toString()] > this.filterNumber;
          } // else return less than
          return i[this.categorySelected.toString()] < this.filterNumber;
        });
      }
      return rows;
    },
    rawData(): object[] {
      return utils.adDataForKey(this, this.dataSource);
    },
    filteredData() {
      let filteredData = this.tableData;
      const lowSearch = this.search.toLowerCase();
      const data = utils.adDataForKey(this, this.dataSource);

      if (lowSearch && lowSearch.length > 2 && data !== undefined) {
        filteredData = filteredData.filter(i => {
          if (i.CreativeId) {
            // return this.getCreativeName(i.CreativeId).toLowerCase().includes(lowSearch);
            return this.getCreativeName(i).toLowerCase().includes(lowSearch);
          } else {
            // search all properties for a string match
            let hasMatch = false;
            for (const property of Object.keys(i)) {
              if (i[property].toString().toLowerCase().includes(lowSearch)) {
                hasMatch = true;
                break;
              }
            }
            return hasMatch;
          }
        });
      }

      const isDesc = !utils.sortByAscMetrics.includes(this.selectedSortBy);
      filteredData = utils.customSort(filteredData, [this.selectedSortBy], [isDesc]);

      // filter out unselect publishers if set in customization
      if (Array.isArray(this.componentConfig.publishers)) {
        filteredData = filteredData.filter(i => this.componentConfig.publishers.includes(i.Publisher));
      }

      // set size limit based on customization settings
      if (this.componentConfig?.itemLimit && parseFloat(this.componentConfig.itemLimit) > 0) {
        filteredData = filteredData.slice(0, parseFloat(this.componentConfig.itemLimit));
      }

      if (!this.isCreativeTactic) {
        return filteredData;
      }

      // filter by tactic, setting the filtered list with first tactic in the list we find.
      if (!this.selectedTacticFilter) {
        if (this.isExporting && Array.isArray(this.tacticFilterList) && this.tacticFilterList.includes('All Tactics')) {
          this.setTacticFilter('All Tactics');
        } else {
          this.setTacticFilter(filteredData[0]?.Tactic);
        }
      }

      return filteredData.filter(i => i.Tactic === this.selectedTacticFilter);
    },
    limitedData() {
      const data = this.filteredData.slice(
        this.tableOptions.page * this.itemsPerPage,
        this.tableOptions.page * this.itemsPerPage + this.itemsPerPage,
      );
      return data;
    },
    filteredEmptyData() {
      const data = this.tableData;
      const newData = [];
      data.forEach(dataObj => {
        const cleanObj = Object.fromEntries(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          Object.entries(dataObj).filter(([_, v]) => Boolean(this.getFloatFromValue(v))),
        );
        newData.push(cleanObj);
      });
      return newData;
    },
    filteredEmptyMetrics() {
      // this creates an array of valid metrics, i.e. metrics without all null/undefined/0 values
      // we only care about this for VCR and VC100 to filter them out of bars and cols
      return Object.keys(Object.assign({}, ...this.filteredEmptyData));
    },
    hideSpend(): boolean {
      // TODO: support multiple campaigns
      // TODO: looks like this is not working at all, investigate and replace/remove/fix
      const campId = this.$store.state.filters.selectedCampaign?.id;
      const details = utils.campaignDetailsById(this)[campId];
      return details?.HideSpend;
    },
    hasCreativeSpendData(): boolean {
      return this.rawData?.some(row => row.CostPerCreative);
    },
    bars(): string[] {
      let bars = this.componentConfig.bars;
      if (!this.hasAdditionalData) {
        bars = utils.filterOutColumns(bars, this.additionalMetrics);
      }
      if (!this.feedSources.includes('SIMPLIFI')) {
        bars = utils.filterOutColumns(bars, ['Actions']);
      }
      if (this.hideSpend) {
        bars = utils.filterOutColumns(bars, ['CostPerClick']);
      }
      if (!this.hasCreativeSpendData) {
        bars = utils.filterOutColumns(bars, ['CostPerCreative']);
      }
      // hide Clicks and CTR for GAM when GAM type is Video
      if (this.componentConfig.dataChecks === 'HasGAMVIDEO') {
        bars = utils.filterOutColumns(bars, ['Clicks', 'ClickThrough']);
      }
      // hide VCR and VC100 if we have no/empty data for them
      if (!this.filteredEmptyMetrics.includes('VCR')) {
        bars = utils.filterOutColumns(bars, ['VCR']);
      }
      if (!this.filteredEmptyMetrics.includes('VC100')) {
        bars = utils.filterOutColumns(bars, ['VC100']);
      }
      if (!this.noConversionsData && !this.isSem) {
        bars = utils.filterOutColumns(bars, ['Conversions', 'CVR']);
      }
      if (this.noVisitsForBroadcast) {
        bars = utils.filterOutColumns(bars, ['Visits']);
      }
      if (this.isYahoo) {
        bars = utils.filterOutColumns(bars, ['CompletionRate']);
      }
      return bars;
    },
    isGAM(): boolean {
      return this.componentConfig.dataChecks === 'HasGAMDISPLAY' || this.componentConfig.dataChecks === 'HasGAMVIDEO';
    },
    totalPages(): number {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    isImage(): boolean {
      return this.componentConfig.creativeType === 'image';
    },
    isPublisherImage(): boolean {
      return this.componentConfig.creativeType === 'publisherImage';
    },
    isTextValue(): boolean {
      return this.componentConfig.creativeType === 'text';
    },
    currentPageNumbers(): string {
      const firstNum = this.tableOptions.page * this.itemsPerPage + 1;
      let secondNum = (this.tableOptions.page + 1) * this.itemsPerPage; // page is 0, so (0+1) * 6 = 6
      if (this.tableOptions.page === this.totalPages - 1 || this.totalPages === 1) {
        secondNum = this.filteredData.length;
      }
      return firstNum + '-' + secondNum + ' of ' + this.filteredData.length;
    },
    disabledNext() {
      return this.tableOptions.page === this.totalPages - 1;
    },
    disabledPrev() {
      return this.tableOptions.page === 0;
    },
    showAdPreview(): boolean {
      return this.dataSource === 'SEM.CreativeList';
    },
    isCreativeTactic(): boolean {
      return this.dataSource?.split('.').includes('CreativeListByTactic');
    },
    tacticFilterList(): Array<string> | null {
      if (this.$store.state.layoutEditor.editMode) return null;
      if (!this.isCreativeTactic) return null;
      return utils.getSetOfTactics(this, this.componentConfig);
    },
    selectedSortBy(): string {
      if (this.filterByValueColumns[this.currKeyIndex]) {
        return this.filterByValueColumns[this.currKeyIndex];
      }
      return 'Impressions';
    },
    primaryColor(): { color: string } {
      let color = getCssVar('--c360-gradient-start');
      if (this.componentConfig.progressBarColor) {
        if (!(this.componentConfig.progressBarColor.indexOf('gradient') > 0)) {
          color = this.componentConfig.progressBarColor;
        }
      }
      return { color };
    },
    hasEnoughData(): boolean {
      if (this.$store.state.layoutEditor.editMode) return true;
      return this.tableData?.length > 0 || this.componentConfig.filter;
    },
    showNoDataChart(): boolean {
      if (this.$store.state.layoutEditor.editMode) {
        return true;
      } else if ((this.componentConfig.hideIfNoData && !this.hasEnoughData) || this.isPrinting) {
        return false;
      }
      return true;
    },
    isPrinting() {
      return this.$route.query.print === 'true';
    },
    isShared(): boolean {
      return this.$store.state.customer?.sharedDashboard;
    },
    overrideHeight(): boolean {
      let override = false;
      const isFullWidth = this.componentConfig?.breakpoints?.includes('lg12');
      if (this.limitedData.length < 3 && isFullWidth && !this.$store.state.layoutEditor.editMode) {
        override = true;
      }
      return override;
    },
    isHalfWidth(): boolean {
      return this.componentConfig?.breakpoints?.includes('lg6');
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    singleColumn(): boolean {
      const hasConfig = typeof this.componentConfig?.singleColumn === 'boolean';
      if (hasConfig && this.componentConfig.singleColumn) {
        return this.componentConfig.singleColumn;
      }
      if (this.isMobile) {
        return true;
      }
      if (this.isHalfWidth) {
        return true;
      }
      return false;
    },
    isBeeswax(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('BEESWAX');
    },
    isXandr(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('XANDR');
    },
    isYahoo(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('YAHOO');
    },
    noConversionsData(): boolean {
      return this?.rawData?.length > 0 && this?.rawData?.some(row => row.Conversions);
    },
    noVisitsForBroadcast(): boolean {
      if (!this.componentConfig?.cid.toLowerCase().includes('broadcast')) return false;
      return this?.rawData?.length > 0 && this?.rawData?.every(row => row.Visits === 0);
    },
    isSem(): boolean {
      return this.isExporting
        ? this.exportData.tab?.toLowerCase() === 'sem'
        : this.$store.state?.customer?.currentNavTab?.toLowerCase() === 'sem';
    },
    isOTTOrVideo(): boolean {
      return this.isExporting
        ? ['preroll', 'ott', 'video'].includes(this.exportData.tab?.toLowerCase())
        : ['preroll', 'ott', 'video'].includes(this.$store.state?.customer?.currentNavTab?.toLowerCase());
    },
    metricLabel(): string {
      if (this.componentConfig?.cid.toLowerCase().includes('publisher') && this.isOTTOrVideo) return 'Imps';
      return '';
    },
    isPerformanceByPublisherModule(): boolean {
      return this.$props.title === 'Performance By Publisher';
    },
    getColumns() {
      if (this.isPerformanceByPublisherModule) {
        return 4;
      }
      return this.singleColumn ? 12 : 6;
    },
    isSortTopToBottom(): boolean {
      return this.componentConfig.sort === 'ttb';
    }
  },
  mounted() {
    if (this.componentConfig.filter) {
      this.filterNumber = this.componentConfig.filter[2];

      this.categorySelected = this.componentConfig.filter[0][0];

      this.categories = this.componentConfig.filter[0];
    }
    if (typeof this.componentConfig.perPage === 'number') {
      this.renderKey = [this.componentConfig.perPage];
    } else if (typeof this.componentConfig.perPage === 'object') {
      this.renderKey = this.componentConfig.perPage;
    }

    unwatchDataChanges = utils.fireOnAdDataChange(
      this,
      () => {
        this.$forceUpdate();
      },
      true,
    );

    this.$nextTick(() => {
      setTimeout(() => {
        // we have to wait for the data to be available to set the page index
        if (this.isExporting && typeof this.componentConfig.showPage === 'number') {
          this.tableOptions.page = this.componentConfig.showPage;
        }
      }, 100);
      setTimeout(this.getDataTableData, 200);
      setTimeout(() => {
        this.calcTooltips();
      }, 3000);
    });
  },
  created() {
    if (this.componentConfig.sortBy) {
      // this sets the default dropdown to whatever sortBy was set to
      this.filterByValue(this.componentConfig.sortBy);
      this.tableOptions.sortBy = [this.componentConfig.sortBy];
      this.tableOptions.sortDesc = [true];
      this.tableOptions.mustSort = true;
    }
    this.setXportingState();
  },
  beforeDestroy(): void {
    unwatchDataChanges();
  },
  watch: {
    filteredData(/* newData */) {
      if (this.isExporting && typeof this.componentConfig.showPage === 'number') {
        return;
      }
      // reset current page we are on, because data has changed
      this.tableOptions.page = this.tableOptions?.exportPage || 0;
    },
    selectedSortBy: {
      async handler(sort: string): Promise<void> {
        utils.updateExportState(this.componentConfig.cid, 'sort', sort);
      },
      immediate: false,
    },
    selectedTacticFilter: {
      async handler(tactic): Promise<void> {
        utils.updateExportState(this.componentConfig.cid, 'tactic', tactic);
      },
      immediate: false,
    },
    search: {
      async handler(search): Promise<void> {
        utils.updateExportState(this.componentConfig.cid, 'search', search);
      },
      immediate: false,
    },
    'tableOptions.page': {
      async handler(page): Promise<void> {
        utils.updateExportState(this.componentConfig.cid, 'page', page);
      },
      immediate: false,
    },
  },
  methods: {
    setXportingState() {
      const exportState = this.$store.state.customer?.dashxExportState?.[this.componentConfig?.cid];
      if (exportState) {
        console.log(`Apply export state for ${this.componentConfig?.cid}`, exportState);
      }
      // selectTactic
      const exportTactic = exportState?.tactic;
      if (exportTactic) {
        this.selectTactic(exportTactic);
      }
      // SortBy
      const sortBy = exportState?.sort;
      const preparedSotyByFields = sortBy && this.filterByValueColumns.find(x => x.toLowerCase() === sortBy.toLowerCase())
      if (preparedSotyByFields) {
        this.tableOptions.sortBy = [preparedSotyByFields];
        this.filterByValue(preparedSotyByFields);
        this.tableOptions.sortDesc = [true];
        this.tableOptions.mustSort = true;

      }
      // search
      const exportSearch = exportState?.search;
      if (exportSearch) {
        this.search = exportSearch;
      }
      // CurrentPage
      const currentPage = exportState?.page;
      if (currentPage) {
        this.tableOptions.exportPage = currentPage;
      }
    },
    selectTactic(tactic) {
      this.selectedTacticFilter = tactic;
    },
    watchSearch(val): void {
      this.search = val;
    },
    getDataTableData(): void {
      if (this.renderEventTriggered) {
        return;
      }
      this.renderEventTriggered = true;
      let simpleExport = true;
      if (this.isXLS) {
        simpleExport = false;
      }
      if (simpleExport) {
        // let table finish to render, to check how many were rendered
        setTimeout(() => {
          if (this.filteredData.length === 0) {
            this.$emit('rendered', { empty: true });
          } else {
            const totalItems = this.filteredData.length;
            this.$emit('rendered', {
              empty: false,
              itemsRenderedCount: this.limitedData.length,
              totalPages: Math.ceil(totalItems / this.itemsPerPage),
              totalItems,
            });
          }
        }, 100);
        return;
      }

      let headers = [];
      // insert columns before regular data
      if (this.componentConfig.exportTweaks?.addCreativeID) {
        headers.push('CreativeId');
      }
      if (this.componentConfig.exportTweaks?.getCreativeName) {
        headers.push('CreativeName');
      }
      if (this.componentConfig.exportTweaks?.addPublisherName) {
        headers.push('Publisher');
      }
      headers = [...headers, ...this.filterByValueColumns];
      // add columns after regular data
      if (this.componentConfig.exportTweaks?.addProgressValue) {
        headers.push('Progress');
      }
      // remove duplicates
      headers = Array.from(new Set([...headers, ...this.filterByValueColumns]));

      if (!this.noConversionsData && !this.isSem) {
        headers = utils.filterOutColumns(headers, ['Conversions', 'CVR']);
      }
      if (this.noVisitsForBroadcast) {
        headers = utils.filterOutColumns(headers, ['Visits']);
      }
      if (this.isYahoo) {
        headers = utils.filterOutColumns(headers, ['CompletionRate']);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let data: any[] = [];
      if (Array.isArray(this.tacticFilterList) && this.tacticFilterList.includes('All Tactics')) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data = this.filteredData.filter((item: any) => item.Tactic === 'All Tactics');
        headers = headers.filter(x => x !== 'Tactic');
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data = this.filteredData.filter((item: any) => item.Tactic !== 'All Tactics');
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data = data.map((item: any) => {
        return headers.map((h: string) => {
          if (h === 'CreativeName') {
            // return this.getCreativeName(item.CreativeId);
            return this.getCreativeName(item);
          }
          if (h === 'Progress') {
            const values = this.progressValuesById(item);
            let metric = values.metricValue;
            let total = values.totalValue || 0;
            let percent;

            if (!values.percent) {
              // calculate percent if not given
              if (typeof metric === 'string') {
                metric = parseFloat(values.metricValue.replace(/[!@#$%^&*]/g, ''));
              }
              if (typeof total === 'string') {
                total = parseFloat(values.totalValue.replace(/[!@#$%^&*]/g, ''));
              }

              if (total === 0) {
                percent = metric === 0 ? '0%' : '100%';
              } else {
                percent = `${((metric / total) * 100).toFixed(0)}%`;
              }
            } else {
              percent = this.values.percent;
            }
            return percent;
          }
          return item[h];
        });
      });
      if (data.length === 0) {
        this.$emit('rendered', { empty: true });
      } else {
        this.$emit('rendered', { empty: false, headers, data, config: this.componentConfig });
      }
    },
    getCreativeName(creative): object | null {
      // best case scenario is we have the creative name
      if (creative.CreativeName) return creative.CreativeName;
      else if (creative.CreativeId) {
        // for some data/feed sources we only get the creative id back and have to look for the name elsewhere
        const data = utils.adDataForKey(this, this.dataSource);
        if (!data) return null;
        const found = data.find((row: object) => row['CreativeId'] === creative.CreativeId);
        return found.CreativeName || found.Creative || `Creative ${this.isImage ? 'Image' : 'Video'}`;
      } else return null;
    },
    getCreativeTactic(id: string | number): string {
      const data = utils.adDataForKey(this, this.dataSource);
      const found = data?.find((row: object) => row['CreativeId'] === id);
      return found?.Tactic;
    },
    labelValue(value, metric): string {
      if (this.componentConfig?.cid === 'ottPerformanceByCreativeProgressBarTable' && value === undefined) {
        return metric === 'Responses' ? '0' : '0.00%';
      }
      if (metric === 'Cost') {
        return value === undefined ? '0.00$' : utils.formatNumberWithCommas(value);
      }
      if (value === undefined) return '-';
      if (typeof value !== 'number') return value;
      if (value < 1) return value.toString(); // handle decimals
      if (metric === 'Response Share') return `${value.toFixed(2)}%`;
      return utils.formatNumberWithCommas(value);
    },
    creativeType(index: number): string {
      if (index > 0) return '';
      return this.componentConfig.creativeType;
    },
    isIcon(type: string): boolean {
      return this.componentConfig.columns.includes(type);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFloatFromValue(value: any): number {
      return parseFloat(String(value).replace(/[^a-zA-Z0-9 ]/g, ''));
    },
    getIcon(value: string): string {
      const icon = utils.iconLibrary[value.toLowerCase()];
      return icon || '';
    },
    changePage(direction: number): void {
      if (direction > 0 && this.tableOptions.page < this.totalPages - 1) {
        this.tableOptions.page += 1;
      } else if (direction < 0 && this.tableOptions.page >= 1) {
        this.tableOptions.page -= 1;
      }
    },
    progressValuesById(creative): ProgressBarValues | null {
      let id, key;
      if (this.isPublisherImage) {
        id = creative.Publisher;
        key = 'Publisher';
      } else if (this.isTextValue) {
        key = this.componentConfig.columns[0];
        id = creative[key];
      } else {
        id = creative.CreativeId;
        key = 'CreativeId';
      }

      const data = utils.adDataForKey(this, this.dataSource);
      let found;
      if (this.isCreativeTactic) {
        found = data?.find((row: object) => row[key] === id && row?.Tactic === this.selectedTacticFilter);
      } else {
        found = data?.find((row: object) => row[key] === id);
      }
      let barMetric = this.selectedSortBy; // defaults to sort by, but can be changed if the sortby is a string rather than a metric (e.g. 'Metro')

      const obj = {
        metricValue: '',
        metricTitle: this.headerName(this.selectedSortBy),
        totalValue: '',
        totalTitle: 'Goal',
        percent: '',
        sortKey: this.selectedSortBy,
      };

      if (found) {
        // metric value

        obj.metricValue = found[this.selectedSortBy];

        // if a string was found instead of a number, get the first metric defined in the component config to be used as a bar value
        if (typeof obj.metricValue === 'string') {
          if (obj.metricValue.includes('%')) {
            obj.metricValue = null;
          } else {
            obj.metricValue = found[this.componentConfig.bars[0]] || 0;
            barMetric = this.componentConfig.bars[0]; // override the sort key to be the first bar metric defined in the component config
          }
        }

        if (utils.percentageMetrics.includes(barMetric)) {
          // total is highest value in dataset
          obj.totalValue = this.filteredData[0][barMetric];
        } else {
          // total is section Total for selected metric
          let tab;
          if (this.isShared) {
            tab = this.$store.state.customer?.sharedSelection?.tab;
          } else {
            tab = this.isExporting ? this.exportData.tab : this.$route.query.tab;
          }
          const sectionKey = utils.dataKeyBySectionIdMap(tab);
          const sectionTotal = utils.adDataForKey(this, `${sectionKey}Total`);
          if (typeof sectionTotal === 'object') {
            obj.totalValue = sectionTotal[barMetric];
          }
        }
        return obj;
      }
      return null;
    },
    progressBarTitleText(creative): string {
      let title = '';
      const value = this.labelValue(creative[this.selectedSortBy], '');
      if (this.isTextValue) {
        title = value;
      } else if (this.isPublisherImage) {
        title = `${creative.Publisher} - ${value}`;
      } else {
        // title = `${this.getCreativeName(creative.CreativeId)} - ${value}`;
        if (this.selectedSortBy.toLowerCase() === 'creativename') title = `${this.getCreativeName(creative)}`;
        // don't show creative name twice
        else title = `${this.getCreativeName(creative)} - ${value}`;
      }

      return title;
    },
    filterByValue(value: string): void {
      this.currKeyIndex = this.filterByValueColumns.indexOf(value);
    },
    headerName(header: string): string {
      if (this.isGAM) {
        if (header === 'Views') return 'Start';
        if (header === 'VC100') return 'Complete';
      }
      if (header === 'Impressions') return 'Imps';
      if (header === 'CompletionRate') return 'VCR';
      return utils.headerNamesMap(header);
    },
    headerNameOverride(header: string): string {
      // temporary fix for names like 'Views' wanting to be displayed at 'Start
      if (header === 'Views') return 'Start';
      else return 'Complete';
    },
    isOverflown(elName: string): boolean {
      const element = this.$refs[elName];
      if (!element || !element[0]) {
        return false;
      }
      return element[0].scrollHeight > element[0].clientHeight || element[0].scrollWidth > element[0].clientWidth;
    },
    calcTooltips() {
      this.limitedData?.forEach(creative => {
        // if (this.isOverflown(creative?.CreativeId ? this.getCreativeName(creative.CreativeId) : creative?.Publisher)) {
        const creativeName = this.getCreativeName(creative);
        if (this.isOverflown(creativeName || creative?.Publisher)) {
          creative?.CreativeId ? this.tooltips.push(creative.CreativeId) : this.tooltips.push(creative?.Publisher);
        }
      });
    },
    setTacticFilter(tactic: string): void {
      this.selectedTacticFilter = tactic;
    },
  },
});
