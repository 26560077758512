
import Vue from 'vue';
import { LayoutSection, SectionComponent } from '../../../types/layout';
import actions from './editors/actions.vue';
import progressBarTable from './editors/edit_progressBarTable.vue';
import genericPie from './editors/edit_genericPie.vue';
import genericLineChart from './editors/edit_genericLineChart.vue';
import genericLineBarChartNew from './editors/edit_genericLineBarChartNew.vue';
import genericTable from './editors/edit_genericTable.vue';
import lineAndBarChart from './editors/edit_lineAndBarChart.vue';
import genericBarChart from './editors/edit_genericBarChart.vue';
import sideSummary from './editors/edit_sideSummary.vue';
import campaignStats from './editors/edit_campaignStats.vue';
import textCampaignSummary from './editors/edit_textCampaignSummary.vue';
import dmaZipMap from './editors/edit_map.vue';
import conversionMap from './editors/edit_map.vue';
import geofenceMap from './editors/edit_map.vue';
import stationMap from './editors/edit_map.vue';
import genericMap from './editors/edit_map.vue';
import siteImpactTable from './editors/edit_siteImpactTable.vue';
import slingNetworkLogosList from './editors/edit_slingNetworkLogosList.vue';
import targetingList from './editors/edit_campaignTargeting.vue';
import tableList from './editors/edit_tableList.vue';
import sectionHeader from './editors/edit_sectionHeader.vue';
import vennDiagram from './editors/edit_vennDiagram.vue';
import benchmarksChart from './editors/edit_benchmarksChart.vue';
import utils from '../../../util';
import _clone from 'lodash.clonedeep';
import analytics from '../../../mixins/analytics';

export default Vue.extend({
  components: {
    actions,
    progressBarTable,
    genericPie,
    genericLineChart,
    genericLineBarChartNew,
    genericTable,
    lineAndBarChart,
    genericBarChart,
    sideSummary,
    campaignStats,
    textCampaignSummary,
    dmaZipMap,
    conversionMap,
    geofenceMap,
    stationMap,
    genericMap,
    siteImpactTable,
    slingNetworkLogosList,
    targetingList,
    tableList,
    sectionHeader,
    vennDiagram,
    benchmarksChart,
  },
  props: ['section', 'component', 'componentHeight', 'sectionConfig', 'componentConfig'],
  mixins: [analytics],
  data: (): {
    currComponentCopy: SectionComponent | null;
    originalOverrides: object | null;
    sectionCopy: LayoutSection | null;
    isEditing: boolean;
    showDeleteConfirmation: boolean;
    editorContainerStyles: {
      width: string;
      height: string;
    };
    opened: boolean;
    disableSave: boolean;
    isAutoHeight: boolean;
  } => ({
    currComponentCopy: null,
    originalOverrides: null,
    sectionCopy: null,
    isEditing: false,
    showDeleteConfirmation: false,
    editorContainerStyles: {
      width: '',
      height: '',
    },
    opened: false,
    disableSave: true,
    isAutoHeight: false,
  }),
  created(): void {
    // make local copies of the section and component and its overrides
    this.currComponentCopy = _clone(this.component);
    this.sectionCopy = _clone(this.section);
    if (this.component.overrides) {
      this.originalOverrides = _clone(this.component.overrides);
    }
    this.isEditing = true;
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    setTimeout(() => {
      this.opened = true;
    }, 100);

    if (this.$refs[this.component.id]?.parentElement?.clientHeight < this.componentHeight) {
      this.isAutoHeight = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setOverride(key: string, value: any): void {
      let customizations = _clone(this.$store.state.layoutEditor.currCustomizations);
      customizations = utils.removeNullFromObjectOrArray(customizations);
      const found = customizations.find(cust => cust.id === this.currComponentCopy.id);
      let newOverride;
      if (found) {
        // should probably make this smarter if other nested overrides
        const keyPath = key.split('.');
        if (keyPath.includes('campaignSummary')) {
          newOverride = { campaignSummary: {} };
          if (found?.overrides?.campaignSummary) {
            newOverride.campaignSummary = _clone(found.overrides.campaignSummary);
          }
          newOverride.campaignSummary[keyPath[1]] = value;
        } else {
          newOverride = {};
          newOverride[key] = value;
        }
        if (found.overrides) {
          found.overrides = { ...found.overrides, ...newOverride };
        } else {
          found['overrides'] = newOverride;
        }

        // DASH-5460: if editing broadcastInnovidXPSectionHeader, apply changes to all other broadcastInnovidXP components
        if (this.currComponentCopy.id === 'broadcastInnovidXPSectionHeader') {
          const comps = customizations.filter(
            cust =>
              cust.id.toLowerCase().includes('broadcastinnovidxp') && cust.id !== 'broadcastInnovidXPSectionHeader',
          );
          for (const c of comps) {
            if (c.overrides) {
              c.overrides = { ...c.overrides, ...newOverride };
            } else {
              c['overrides'] = newOverride;
            }
          }
        }
        this.$store.dispatch('layoutEditor/setCurrCustomizations', utils.removeNullFromObjectOrArray(customizations));
        this.disableSave = false;
        let formattedKey = key;
        switch (key) {
          case 'showOneLine':
            formattedKey = 'Line Display Options';
            break;
          case 'lineSeriesKeys':
          case 'campaignSummary.topMetrics':
            formattedKey = 'Metrics';
            break;
        }
        key === 'breakpoints'
          ? this.analyticTrack(this.trackValue.EVENT_ADJUST_WIDTH, `${this.component.title}`)
          : this.analyticTrack(
              this.trackValue.EVENT_CHANGE_MODULE_SETTING,
              `${this.component.title} - ${formattedKey}`,
            );
      }
    },
    cancel(): void {
      // undo overrides
      const found = this.$store.state.layoutEditor.currCustomizations.find(
        cust => cust.id === this.currComponentCopy.id,
      );
      if (found) {
        if (this.originalOverrides) {
          found.overrides = this.originalOverrides;
        } else {
          delete found.overrides;
        }
      }
      this.clear();
    },
    deleteComponent(): void {
      const customizations = this.$store.state.layoutEditor.currCustomizations.filter(
        cust => cust?.id?.toLowerCase() !== this.currComponentCopy.id.toLowerCase(),
      );

      const { dynamicLayout } = this.$store.state.customer;
      const dynamicLayoutComps = dynamicLayout.layoutCustomizations.config.components.find(
        c => c.type !== 'summaryonly',
      );
      dynamicLayoutComps.components = dynamicLayoutComps.components.filter(comp => {
        const found = customizations.find(cust => cust?.id?.toLowerCase() === comp?.id?.toLowerCase());
        return found;
      });

      this.showDeleteConfirmation = false;
      this.$store.dispatch('setLayout', dynamicLayout);
      this.$store.dispatch('layoutEditor/setCurrCustomizations', customizations);
      this.$store.dispatch('layoutEditor/setEnableSave', true);
      this.analyticTrack(
        this.trackValue.EVENT_CONFIGURABILITY,
        `${this.trackValue.REMOVE_MODULE}: ${this.component.title}`,
      );
      this.clear();
    },
    save(): void {
      const currCustomization = this.$store.state.layoutEditor.currCustomizations.find(
        cust => cust.id === this.currComponentCopy.id,
      );

      if (this.currComponentCopy && currCustomization?.overrides) {
        // apply overrides to layout
        const newOverrides = {};
        Object.keys(currCustomization.overrides).forEach(key => {
          if (key === 'campaignSummary') {
            if (!newOverrides['campaignSummary']) {
              newOverrides['campaignSummary'] = {};
            }
            Object.keys(currCustomization.overrides.campaignSummary).forEach(subKey => {
              // hack around DSStore saving empty arrays as null
              let metrics = currCustomization.overrides.campaignSummary[subKey];
              if (metrics[0] === 'empty') metrics = [];
              newOverrides['campaignSummary'][subKey] = metrics;
            });
          } else {
            newOverrides[key] = currCustomization.overrides[key];
          }
        });
        this.$store.dispatch('setOverrides', { compId: this.currComponentCopy.id, overrides: newOverrides });
        this.analyticTrack(
          this.trackValue.EVENT_CONFIGURABILITY,
          `${this.trackValue.SAVE_MODULE}: ${this.component.title}`,
        );

        // DASH-5460: if editing broadcastInnovidXPSectionHeader, apply changes to all other broadcastInnovidXP components
        if (this.currComponentCopy.id === 'broadcastInnovidXPSectionHeader') {
          const comps = this.$store.state.layoutEditor.currCustomizations.filter(
            cust =>
              cust.id.toLowerCase().includes('broadcastinnovidxp') && cust.id !== 'broadcastInnovidXPSectionHeader',
          );
          for (const c of comps) {
            let over;
            if (c.overrides) {
              over = { ...c.overrides, ...newOverrides };
            } else {
              over = newOverrides;
            }
            this.$store.dispatch('setOverrides', { compId: c.id, overrides: over });
          }
        }
      }

      this.$store.dispatch('layoutEditor/setEnableSave', true);
      this.clear();
    },
    clear(): void {
      this.currComponentCopy = null;
      this.sectionCopy = null;
      this.isEditing = false;
      this.opened = false;
      this.disableSave = true;
      setTimeout(() => {
        this.$store.dispatch('layoutEditor/closeEditComponent');
      }, 500);
    },
    onResize(): void {
      this.editorContainerStyles = {
        width: `${this.$refs[this.component.id].parentElement.clientWidth - 15}px`,
        height: `${this.$refs[this.component.id].parentElement.clientHeight - 15}px`,
      };
    },
  },
  computed: {
    sectionId(): string {
      return this.section?.id;
    },
    componentId(): string {
      return this.component?.id;
    },
    isHalfModule(): boolean {
      return this.currComponentCopy.breakpoints.includes('lg6');
    },
    showRemoveButton(): boolean {
      return !this.componentId.toLowerCase().includes('broadcastinnovidxp');
    },
  },
});
