
import Vue from 'vue';
import Tooltips from '../../charts/tooltip/chartTooltip.vue';

const tooltipsTitle = [
  { id: 1, title: '', message: '' },
  {
    id: 2,
    title: 'Attributions',
    message:
      'Depending on the campaign setup, this tab may house website conversion reporting, consumer spend attribution, call tracking, and a retargeting summary.',
  },
];

export default Vue.extend({
  name: 'TabsComponent',
  components: {
    Tooltips,
  },
  data: () => ({
    tooltipsTitle,
  }),
  props: {
    showTab: {
      type: Array,
      default: () => [1, 2],
    },
  },
  mounted() {
    if (!this.$route.query.viewtab) {
      this.$router.replace({ query: { ...this.$route.query, viewtab: '1' } });
    }
  },
  computed: {
    tabs() {
      return [
        { id: 1, name: 'Fulfillment' },
        { id: 2, name: 'Attribution' },
      ];
    },
    filteredTabs() {
      return this.tabs.filter(tab => this.showTab.includes(tab.id));
    },
    activeTab() {
      return Number(this.$route.query.viewtab) || 1;
    },
  },
  methods: {
    selectTab(tab) {
      if (this.activeTab === tab.id) return;
      this.$router.push({ query: { ...this.$route.query, viewtab: tab.id.toString() } });
    },
    isActive(tab): boolean {
      return this.activeTab === tab.id;
    },
    defineTooltipsTitle(id: number) {
      return [this.tooltipsTitle.find(tooltip => tooltip.id === id)];
    },
  },
});
