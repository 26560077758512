
import Vue from 'vue';
import { format } from 'date-fns';
import analytics from '../../../../mixins/analytics';
import { Order } from '../../../../store/modules/primary/types';

const LIMIT_NUMBER = 5;

export default Vue.extend({
  name: 'OrderCard',
  data() {
    return {
      LIMIT_NUMBER,
    };
  },
  props: {
    order: {
      type: Object as () => Order,
      required: true,
    },
  },
  mixins: [analytics] as any,
  computed: {
    showDate(): boolean {
      return !this.order?.Pacing;
    },
    showPacing(): boolean {
      return this.order?.Pacing;
    },
    extractedNumber(): number {
      const numericString = this.order?.TimeLeft?.replace(/\D/g, '');
      return Number(numericString);
    },
  },
  methods: {
    formatDate(dateStr): string {
      if (!dateStr) return 'No data';
      return format(new Date(dateStr), 'MMM d, yyyy');
    },
    pushToOrder() {
      if (!this.order.AdvertiserId && !this.order.OrderId) return;
      this.analyticTrack(
        this.trackValue.POST_LOGIN,
        `Go to Order "${this.order.FriendlyName || this.order.OrderName}"`,
      );
      const queryParams = { id: this.order.AdvertiserId, tab: 'ordersummary', orderId: this.order.OrderId };
      this.$router.push({ query: queryParams });
    },
    getPacingStyle(value: string | undefined): string {
      if (!value || value === 'N/A' || value === '-') return 'pacing-na';
      const parsedValue = parseFloat(value);

      const [normalPacingBorder, goodPacingBorder, badPacingBorder] = [-5.0, 0.0, 5.0];

      if (parsedValue >= goodPacingBorder && parsedValue < badPacingBorder) {
        return 'pacing-good';
      } else if (parsedValue >= normalPacingBorder && parsedValue < goodPacingBorder) {
        return 'pacing-normal';
      } else return 'pacing-bad';
    },
  },
});
