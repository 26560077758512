
import Vue from 'vue';
import utils from '../../../../util';
import { format, parse } from 'date-fns';
import { Campaign } from '../../../../types/filters';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import NoChartData from '../no-data/NoChartData.vue';
import CampaignStats from './campaignStats.vue';

export default Vue.extend({
  inheritAttrs: false,
  name: 'textCampaignSummary',
  components: { EditModuleBtn, NoChartData, CampaignStats },
  props: [
    'sectionConfig',
    'componentConfig',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'componentHeight',
  ],
  data: (): {} => ({}),
  mounted(): void {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.details?.length > 0) {
          this.$emit('rendered', { empty: false });
        } else {
          this.$emit('rendered', { empty: true });
        }
      }, 10);
    });
  },
  computed: {
    details(): Array<object> | null {
      let details;
      if (this.isExporting) {
        if (Array.isArray(this.exportData.adData.CampaignList) && this.exportData.adData.CampaignList.length > 0) {
          details = this.exportData.adData.CampaignList;
        } else if (
          Array.isArray(this.exportData.advertiser.CampaignList) &&
          this.exportData.advertiser.CampaignList.length > 0
        ) {
          details = this.exportData.advertiser.CampaignList;
        }
      } else {
        details = this.campaignIds?.reduce((all: Array<object>, id: string) => {
          const detail = utils.campaignDetailsById(this)[id];
          if (detail) {
            all.push(detail);
          }
          return all;
        }, []);
      }
      if (!details) {
        return null;
      }
      return details;
    },
    campaignIds(): string {
      if (this.isExporting) {
        return this.exportData.campaignIds;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return this.$store.state.filters.selectedCampaigns?.filter((c: any) => !!c).map((c: Campaign) => c.id);
    },
    summaryData(): { title: string; value: string }[] | null {
      if (this.details.length) {
        const dates = this.details.map(x => this.formatDate(x.StartDate));
        const data = [
          { title: 'From Line (Brand)', value: this.details.map(x => x.Brand).join(' | ') },
          { title: 'Subject Line (Headline)', value: this.details.map(x => x.Headline).join(' | ') },
          { title: 'Deployment/Broadcast Date', value: dates.join(' | ') },
          { title: 'ID', value: this.details.map(x => x.CampaignId).join(' | ') },
        ];
        // add campaign spending if enabled and only one campaign selected
        const type = this.details[0].SpendType;
        if (type && this.details.length === 1) {
          if (type === 'CPM') {
            const cpm = this.details.map(x => utils.formatNumberWithCommas(parseInt(x.SpendRate)));
            data.push({ title: 'Cost Per Thousand (CPM)', value: `$${cpm[0]}.00` });
            data.push({ title: 'Total Spend', value: this.details[0]?.SpendVal });
          } else if (type === 'CPD') {
            const costFloat = parseFloat(this.details[0]?.CostPerDeployment.replace('$', ''));
            const cost = costFloat.toFixed(2);
            data.push({
              title: 'Cost Per Deployment (CPD)',
              value: `$${cost}`,
            });
          }
        }
        return data;
      }
      return null;
    },
    loading(): boolean {
      return utils.isWaitingOnData(this) && this.$store.state.filters.selectedCampaigns;
    },
    showLastUpdated(): boolean {
      if (this.$route.query?.viewCampaigns?.includes(',')) {
        return false;
      } else return true;
    },
    lastModifiedDate(): string {
      return utils.getLastModifiedDate(this).toString();
    },
    lastUpdatedToolTip(): string {
      return utils.getTooltipsFromMetric('lastupdated')?.message;
    },
  },
  methods: {
    formatDate(date: string): string {
      try {
        const parsedDate = parse(date, 'MM/dd/yyyy hh:mm:ss a', new Date()); // parse custom date format
        return format(parsedDate, 'MMM d, yyyy');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('campaign date', date, err);
      }
      return '';
    },
  },
});
