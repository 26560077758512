
import Vue from 'vue';
import { GeoTargets, GeoTargetsData } from '../../../../../types/geoTargets';
import { Segment, Segments } from '../../../../../types/segment';
import NoChartData from '../../no-data/NoChartData.vue';
import EditModuleBtn from '../../buttons/editModuleBtn.vue';
import ExportDownloadBtn from '../../buttons/exportDownloadBtn.vue';
import GeoDataView from './_geoDataView.vue';
import GeoDmaView from './_geoDMAView.vue';
import DemographicView from './_demographicView.vue';
import SegmentView from './_segmentView.vue';
import Tooltips from '../../tooltip/chartTooltip.vue';
import utils from '../../../../../util';
import { Tooltip } from '../../../../../../../../shared/dashboardLayouts/layout-components/types/layoutTypes';

let unwatchDataChanges: () => void;

export default Vue.extend({
  inheritAttrs: false,
  name: 'targetingList',
  components: {
    NoChartData,
    GeoDataView,
    DemographicView,
    SegmentView,
    GeoDmaView,
    EditModuleBtn,
    ExportDownloadBtn,
    Tooltips,
  },
  props: ['sectionConfig', 'componentConfig', 'title', 'dataSource', 'isExporting', 'exportData', 'componentHeight'],
  data: (): {
    tab: null;
    itemsPerPage: number;
    currentPage: number;
  } => ({
    tab: null,
    itemsPerPage: 7,
    currentPage: 1,
  }),
  created() {
    this.setXportingState();
  },
  mounted() {
    this.initChart();
    unwatchDataChanges = utils.fireOnAdDataChange(this, this.initChart, true);
    if (this.isExporting) {
      const cfg = this.exportData?.userState?.[this.componentConfig.cid];
      if (cfg?.selectedTab) {
        this.tab = cfg.selectedTab;
      } else if (!this.tab) {
        this.tab = 'tab-0';
      }
    }
  },
  beforeDestroy() {
    unwatchDataChanges();
  },
  computed: {
    dataFilledTooltips(): Tooltip[] {
      const tips = [...this.componentConfig?.tooltips];
      if (this.isXandr) {
        const CampaignTargetingXandrTip = `<br><br>If a custom date range is selected, we don’t have this information from the ad platform to be able to show only the campaign targets for that custom date range. Therefore, you will always see the campaign targets
          for the entire campaign, regardless of when each target started or stopped.
          In this module, it would appear as if all the campaign targets were active for the entire campaign’s flight, but some might have started and stopped on specific dates.
          We cannot show that information at this time.`;
        tips.find(tip => tip.title === 'Campaign Targeting' && !tip.message.includes(CampaignTargetingXandrTip))?.message += CampaignTargetingXandrTip;
      }
      return tips;
    },
    loading(): boolean {
      return utils.isWaitingOnData(this);
    },
    hasEnoughData(): boolean {
      // if (!this.validFeedSource) return false;
      if (this.$store.state.layoutEditor.editMode) return true;
      return this.allData?.length !== 0;
    },
    showNoDataChart(): boolean {
      if (this.$store.state.layoutEditor.editMode) {
        return true;
      } else if ((this.componentConfig.hideIfNoData && !this.hasEnoughData) || this.isPrinting) {
        return false;
      }
      return true;
    },
    isPrinting() {
      return this.$route.query.print === 'true';
    },
    demographicsData(): object[] | [] {
      const data = utils.adDataForKey(this, 'SIMPGEOFENCE.CampaignTarget');
      if (!data) return [];
      const dataKeys = Object.keys(data).filter(item => item !== 'CampaignId');
      const demographics = dataKeys.map(item => {
        const isString = typeof data[item] === 'string';
        return {
          icon: this.getDemographicIcon(item),
          title: item,
          values: isString ? [data[item]] : data[item],
        };
      });
      // return demographics;
      return [
        {
          type: 'demo',
          icon: 'assessment',
          title: 'Demographics',
          values: demographics,
        },
      ];
    },
    geoData(): GeoTargets[] | [] {
      let dataKey = utils.dataKeyBySectionIdMap(this.sectionConfig.id);
      if (this.isExporting) {
        dataKey = utils.dataKeyBySectionIdMap(this.exportData?.tab);
      }
      const totalData = utils.adDataForKey(this, `${dataKey}Total`);
      if (totalData) {
        if (totalData['GeoTargetings']) {
          const data = totalData['GeoTargetings'];
          const geoTargets = data.reduce((arr: GeoTargets[], geoTarget: GeoTargetsData) => {
            if (geoTarget.values && Array.isArray(geoTarget.values) && geoTarget.values.length) {
              if (geoTarget?.DisplayName?.toLowerCase() === 'dmas') {
                // data is a array of strings, convert to correct object for presentation;
                let dmaCode;
                const list = geoTarget.values.reduce((acc, value, i) => {
                  if (i % 2 === 1) acc.push({ code: dmaCode, city: value });
                  else dmaCode = value;
                  return acc;
                }, []);
                const geoData = {
                  type: 'dma',
                  icon: this.getDemographicIcon(geoTarget.type),
                  title: 'DMA TARGET',
                  DMA: list,
                };
                arr.push(geoData);
              } else {
                const joinBy = geoTarget?.DisplayName?.toLowerCase() === 'cities' ? '; ' : ', ';
                const geoData = {
                  type: 'geo',
                  icon: this.getDemographicIcon(geoTarget.type),
                  title: geoTarget.DisplayName || '',
                  values: geoTarget.values.sort((a: string, b: string) => a.localeCompare(b)).join(joinBy),
                };
                arr.push(geoData);
              }
            }
            return arr;
          }, []);
          // this wasn't returning any Geotargetings and using the fallbacks
          // if (geoTargets.length > 1) return geoTargets;
          if ((geoTargets[0] && geoTargets[0]?.values?.length) || (geoTargets[0] && geoTargets[0]?.DMA?.length)) {
            return geoTargets;
          } else if (totalData['GeoTargeting']) {
            // Fallback for now if new data is not present
            return this.geoTargetFallback(totalData['GeoTargeting']);
          } else return [];
        } else if (totalData['GeoTargeting']) {
          // Fallback for now if new data is not present
          return this.geoTargetFallback(totalData['GeoTargeting']);
        }
      }

      return [];
    },
    segmentData(): Segments[] | [] {
      let dataKey = utils.dataKeyBySectionIdMap(this.sectionConfig.id);
      if (this.isExporting) {
        dataKey = utils.dataKeyBySectionIdMap(this.exportData?.tab);
      }
      const totalData = utils.adDataForKey(this, `${dataKey}Total`);
      if (totalData && totalData['ByAudienceTarget']) {
        const data = totalData['ByAudienceTarget'];
        // check for empty array.
        if (Array.isArray(data) && data.length === 0) return [];
        const segments: Array<Segment> = data.map((segment: Segment): Segment => {
          // scrub to use updated property names
          if (segment?.Segment) {
            return {
              Segment: `${segment.Segment}`,
              SegmentType: `${segment?.SegmentType || 'N/A'}`,
            };
          }
        });
        return [
          {
            type: 'segment',
            icon: 'my_location',
            title: this.isExporting ? 'AUDIENCE TARGET' : 'Audience target',
            values: segments,
            tooltipValues: segments,
          },
        ];
      } else return [];
    },
    allData() {
      return [...this.geoData, ...this.segmentData, ...this.demographicsData];
    },
    showTabs(): boolean {
      return this.allData?.length > 0;
    },
    canExportToXLS(): boolean {
      if (this.$store.state.customer.currentSection?.xlsExportLocalOnly) {
        if (!utils.isLocalDev()) {
          return false;
        }
      }
      if (this.$store.state.customer.currentSection?.xlsExportDevOnly) {
        if (!utils.isLocalDev() && !utils.isDevelopment()) {
          return false;
        }
      }
      return this.hasEnoughData && !!this.componentConfig?.exportableTableData;
    },
    isXandr(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('XANDR');
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
    feedSources(): Array<string> {
      return utils.feedSources(this);
    },
    hasCustomizedFeedSource(): boolean {
      return this.componentConfig?.feedSource?.length || this.componentConfig?.feedSourceToExclude?.length;
    },
    validFeedSource(): boolean {
      if (!this.componentConfig?.feedSource?.length && !this.componentConfig?.feedSourceToExclude?.length) return true;
      if (this.componentConfig?.feedSourceToExclude?.length) {
        return !this.componentConfig.feedSourceToExclude.some(item => this.feedSources.includes(item));
      }
      if (this.componentConfig?.feedSource?.length) {
        return this.componentConfig.feedSource.some(item => this.feedSources.includes(item));
      }
      return false;
    },
    paginatedData(): Segments[] | [] {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.segmentData[0]?.values?.slice(start, start + this.itemsPerPage);
    },
  },
  methods: {
    setXportingState() {
      const exportState = this.$store.state.customer?.dashxExportState?.[this.componentConfig?.cid];
      if (exportState) {
        console.log(`Apply export state for ${this.componentConfig?.cid}`, exportState);
      }
      // Order of applying params is crucial, since some of them depend on others
      // 1. exportTab
      const exportTab = exportState?.selectedTab;
      if (exportTab) {
        this.tab = exportTab;
      }
    },
    initChart(): void {
      setTimeout(() => {
        if (!this.hasEnoughData) {
          this.$emit('rendered', { empty: true });
        } else {
          try {
            const headers = [];
            const data = [];
            let maxCount = 0;
            this.allData.forEach(metric => {
              if (metric.type === 'geo') {
                headers.push(metric?.title);
                if (Array.isArray(metric?.values)) {
                  data.push(metric.values);
                  if (metric.values.length > maxCount) {
                    maxCount = metric.values.length;
                  }
                } else if (typeof metric?.values === 'string') {
                  let list = metric.values.split(';');
                  if (list.length === 1) {
                    list = metric.values.split(',');
                  }
                  list = list.map(s => s.trim());
                  data.push(list);
                  if (list.length > maxCount) {
                    maxCount = list.length;
                  }
                }
              }
              if (metric.type === 'demo') {
                metric.values.forEach(val => {
                  headers.push(val?.title);
                  data.push(val?.values);
                  if (val?.values?.length > maxCount) {
                    maxCount = val.values.length;
                  }
                });
              }
              if (metric.type === 'segment') {
                const segs = [];
                headers.push(metric?.title);
                metric.values.forEach(val => {
                  const segValue = `${val?.SegmentType}: ${val?.Segment}`;
                  segs.push(segValue);
                });
                data.push(segs);
                if (segs?.length > maxCount) {
                  maxCount = segs.length;
                }
              }
              if (metric.type === 'dma') {
                const dmas = [];
                headers.push(metric?.title);
                metric?.DMA.forEach(val => {
                  dmas.push(`${val?.code}: ${val?.city}`);
                });
                if (dmas?.length > maxCount) {
                  maxCount = dmas.length;
                }
                data.push(dmas);
              }
            });
            // data is in rows, needs to be in columns
            const rows = [];
            for (let j = 0; j < maxCount; j++) {
              rows[j] = Array(headers.length);
            }
            for (let i = 0; i < data.length; i++) {
              for (let j = 0; j < data[i].length; j++) {
                rows[j][i] = data[i][j];
              }
            }
            if (!this.validFeedSource) {
              this.$emit('rendered', { empty: true });
              return;
            }
            this.$emit('rendered', { empty: false, headers, data: rows, config: this.componentConfig });
          } catch (err) {
            this.$emit('rendered', { empty: true, error: err });
          }
        }
      }, 10);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    checkType(value: any, check: string): boolean {
      return value?.type === check;
    },
    getIcon(data) {
      return data?.icon;
    },
    getTitle(data) {
      // DASH-3758: change title of Zip Codes metric for OTT campaigns
      if (this.$store.state.customer.currentNavTab === 'ott' && data?.title === 'Zip Codes') {
        return 'Zip/Postal Codes';
      }

      return data?.title;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onRendered(context: any): void {
      if (this.validFeedSource) {
        this.$emit('rendered', context);
      } else {
        this.$emit('rendered', { empty: true });
      }
    },
    initialDataCheck(): boolean {
      return !!utils.adDataForKey(this, this.componentConfig.dataSource);
    },
    getDemographicIcon(d: string): string {
      const icons = {
        age: 'cake',
        education: 'school',
        gender: 'person',
        'marital status': 'favorite',
        race: 'invert_colors',
        income: 'payments',
        geotargeting: 'location_on',
        zips: 'location_on',
        dmacodes: 'map',
        cities: 'location_city',
        states: 'terrain',
      };
      return icons[d.toLowerCase()];
    },
    geoTargetFallback(data: string): GeoTargets[] {
      const arr = data.split(',');
      let tooltipValues = '';
      for (let i = 0; i < arr.length; i++) {
        if (arr[i + 1]) {
          tooltipValues += arr[i] + ', ';
        } else {
          tooltipValues += arr[i];
        }
      }
      return [
        {
          icon: this.getDemographicIcon('GeoTargeting'),
          title: 'Geo Target',
          values: tooltipValues,
          tooltipValue: tooltipValues,
          type: 'geo',
        },
      ];
    },
  },
  watch: {
    allData: {
      handler(): void {
        // hide right away if incorrect feed source
        // show if in edit mode whatever the data is
        // show if there is data, hide if not + hideIfNoData in component config is true
        if (this.hasCustomizedFeedSource && !this.validFeedSource) {
          this.$emit('set-display', false);
          return;
        }
        if (this.$store.state.layoutEditor.editMode) {
          this.$emit('set-display', true);
          return;
        }
        if (!this.allData.length && this.componentConfig.hideIfNoData) {
          this.$emit('set-display', false);
        } else {
          this.$emit('set-display', true);
        }
      },
      immediate: true,
      deep: true,
    },
    '$route.query.viewCampaigns': {
      handler(): void {
        // hide right away if incorrect feed source
        // show if in edit mode whatever the data is
        // show if there is data, hide if not + hideIfNoData in component config is true
        if (this.hasCustomizedFeedSource && !this.validFeedSource) {
          this.$emit('set-display', false);
          return;
        }
        if (this.$store.state.layoutEditor.editMode) {
          this.$emit('set-display', true);
          return;
        }
        if (this.allData.length) {
          this.$emit('set-display', true);
        } else {
          this.$emit('set-display', false);
        }
      },
      immediate: true,
      deep: true,
    },
    tab: {
      async handler(newSort: string[]): Promise<void> {
        if (this.isExporting) {
          return;
        }
        const exportState = this.$store.getters.exportState || {};
        exportState[this.componentConfig.cid] = {};
        if (newSort?.length) {
          const selectedTab = this.tab;
          exportState[this.componentConfig.cid] = { selectedTab };
          utils.updateExportState(this.componentConfig.cid, 'selectedTab', selectedTab);
        }
        this.$store.dispatch('setExportState', exportState);
      },
      immediate: false,
    },
  },
});
