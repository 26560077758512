
import Vue from 'vue';
import breakpoints from '../editorOptions/breakpoints.vue';
import mapLayoutBtns from '../editorOptions/mapLayout.vue';
import campaignSummaryMetrics from '../editorOptions/campaignSummaryMetrics.vue';
import mapDataConfig from '../editorOptions/mapDataConfig.vue';
import utils from '../../../../util';

interface Metric {
  title: string;
  key: string;
}

export default Vue.extend({
  components: {
    breakpoints,
    mapLayoutBtns,
    campaignSummaryMetrics,
    mapDataConfig,
  },
  props: ['componentData', 'componentConfig', 'sectionConfig'],
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setOverride(key: string, value: any): void {
      this.$emit('setOverride', key, value);
    },
  },
  computed: {
    isBeeswax(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('BEESWAX');
    },
    isGroundTruth(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('GROUNDTRUTH');
    },
    isMapOnly(): boolean {
      return false;
    },
    isMapAndSummary(): boolean {
      return !this.componentData.onlyMap && !this.componentData.onlySummary;
    },
    showMetricOptions(): boolean {
      const hasSummary = !this.componentData.onlyMap;
      const hasMetrics = this.availableTopMetrics || this.availableBottomMetrics;
      return hasSummary && hasMetrics;
    },
    availableTopMetrics(): Array<Metric> {
      let filtered: Array<Metric> = [];
      const metrics = this.componentData.campaignSummary?.availableTopMetrics;
      if (metrics) {
        filtered = utils.filteredMetrics(this, metrics);
      }
      return filtered;
    },
    topMetrics(): Array<String> {
      return this.componentData.campaignSummary?.topMetrics;
    },
    availableBottomMetrics(): Array<Metric> {
      let filtered: Array<Metric> = [];
      const metrics = this.componentData.campaignSummary?.availableBottomMetrics;
      if (metrics) {
        filtered = utils.filteredMetrics(this, metrics);
      }

      return filtered;
    },
    bottomMetrics(): Array<String> {
      return this.componentData.campaignSummary?.bottomMetrics;
    },
    impressionsProgressBar(): boolean {
      return this.componentData.campaignSummary?.impressionsProgressBar;
    },
  },
});
