
import Vue from 'vue';
import utils from '../../../../util';
import { format } from 'date-fns';
import ProgressBar from '../../progressBars/progressBar.vue';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import NoChartData from '../no-data/NoChartData.vue';
import { Tooltip } from '../../../../../../../shared/dashboardLayouts/layout-components/types/layoutTypes';
import ChartTooltip from '../tooltip/chartTooltip.vue';
import VerificationLogo from '../../maps/mapCampaignSummary/modules/_verificationLogo.vue';
import { mappedRange } from '../../toolbar/date-selection/utils';
import { summaryDaterangeDefault } from '../../toolbar/date-selection/constants';

let unwatchDataChanges: () => void;

interface SummaryMetric {
  title: string;
  value: string;
  key: string;
}

export default Vue.extend({
  inheritAttrs: false,
  name: 'campaignOverview',
  components: { ProgressBar, EditModuleBtn, NoChartData, ChartTooltip, VerificationLogo },
  props: [
    'sectionConfig',
    'componentConfig',
    'title',
    'dataSource',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'isForcedToBeFullWidth',
  ],
  data() {
    return {
      metricOverTotal: null,
      dateRangeSelected: '',
      totalValue: null,
    };
  },
  created() {
    this.metricOverTotal = this.calcMetricOverTotal(this.progressBarValues);
    this.dateRangeSelected = this.calcDateRangeSelected();
  },
  mounted(): void {
    if (!this.isExporting && !this.isPrinting) {
      unwatchDataChanges = utils.fireOnAdDataChange(
        this,
        () => {
          this.$forceUpdate();
        },
        true,
      );
      setInterval(() => {
        this.$forceUpdate();
      }, 3000);
    }
    this.$nextTick(() => {
      setTimeout(() => {
        if ((this.topMetrics && this.topMetrics.length > 0) || this.showImpressionsProgressBar) {
          this.$emit('rendered', { empty: false });
        } else {
          this.$emit('rendered', { empty: true });
        }
      }, 10);
    });
  },
  beforeDestroy() {
    if (unwatchDataChanges) {
      unwatchDataChanges();
    }
  },
  computed: {
    isPrinting(): boolean {
      return this.$route.query.print === 'true';
    },
    showCampaignType(): boolean {
      if (!this.getTacticNames.toLowerCase().includes('youtube')) return false;
      if (!this.campaignDetails?.AdFormats?.length) return false;
      return true;
    },
    campaignType(): string {
      return this.campaignDetails?.AdFormats.join(', ');
    },
    hideZeros(): boolean {
      return this.componentConfig.hideZeros;
    },
    isTv2ott(): boolean {
      return this.isExporting
        ? this.componentConfig.tacticName === 'tv2ott'
        : this.$store.state.customer.currentNavTab === 'tv2ott';
    },
    topMetrics(): Array<SummaryMetric> {
      if (!this.campaignDetails || !this.componentConfig?.topMetrics) return [];

      let metrics: Array<SummaryMetric> = [];
      const { topMetrics } = this.componentConfig;

      if (Array.isArray(topMetrics)) {
        const filtered = utils.filteredMetrics(this, topMetrics);

        // add in metric values
        metrics = filtered.map((metric: SummaryMetric) => {
          return {
            title: metric.title,
            value: this.formatValue(metric),
            key: metric.key,
          };
        });
      }
      return metrics;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCampaignDates(): any[] {
      return utils.getCampaignDateRange(this);
    },
    getTacticNames(): string {
      if (this.selectedCampaigns.length === 1) {
        let list = [];
        if (this.isExporting) {
          const c = this.selectedCampaigns[0];
          list = c.Tactics;
          if (!list && c.CampaignType) {
            list = [c.CampaignType];
          }
          if (!list && c.AnalyticsType) {
            list = [c.AnalyticsType];
          }
        } else {
          if (Array.isArray(this.campaignDetails?.Tactics)) {
            if (this.campaignDetails.Tactics.length === 0) {
              return 'N/A';
            }
            list = this.campaignDetails.Tactics;
          } else {
            const c = this.selectedCampaigns[0];
            list = c.Tactics || c.CampaignType ? [c.CampaignType] : [];
          }
        }
        if (!list || list.length === 0) {
          return 'N/A';
        }
        return list
          .map((tactic: string) => {
            return utils.getTacticName(tactic);
          })
          .join(', ');
      } else {
        if (!this.campaignDetails) {
          return '';
        }
        return utils.getTacticName(this.campaignDetails.AnalyticsType);
      }
    },
    campaignDetails(): object | null {
      if (this.selectedCampaigns.length < 1) {
        return null;
      }
      let combinedSummary;
      const firstCampaign = this.selectedCampaigns[0];
      if (this.isExporting) {
        const t = firstCampaign?.AnalyticsType || firstCampaign?.CampaignType;
        if (!t) {
          return null;
        }
        combinedSummary = utils.getSummmaryDetailsByType(this, t);
      } else {
        if (!firstCampaign || !firstCampaign.CampaignType) {
          return null;
        }
        combinedSummary = utils.getSummmaryDetailsByType(this, firstCampaign.CampaignType);
      }
      if (!combinedSummary) {
        return null;
      }
      return combinedSummary;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedCampaigns(): any[] {
      if (this.isExporting) {
        // if (this.exportData.campaigns?.Campaign && Array.isArray(this.exportData.campaigns.Campaign)) {
        //   return this.exportData.campaigns.Campaign;
        // }

        if (this.exportData.campaignIds && Array.isArray(this.exportData.adData.CampaignList)) {
          return this.exportData.adData.CampaignList;
        }

        const tacticName =
          this.componentConfig.AnalyticsType ||
          this.sectionConfig.AnalyticsType ||
          this.exportData.layout.AnalyticsType;

        // filter all campaigns for the current tactic
        if (Array.isArray(this.exportData.adData.CampaignList)) {
          return this.exportData.adData.CampaignList.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (c: any) => c.AnalyticsType === tacticName,
          );
        }
      }
      if (this.$store.state.filters.selectedCampaigns && Array.isArray(this.$store.state.filters.selectedCampaigns)) {
        return this.$store.state.filters.selectedCampaigns;
      }
      return [];
    },
    loading(): boolean {
      return utils.isWaitingOnData(this);
    },
    showImpressionsProgressBar(): boolean {
      if (this.componentConfig) {
        return this.componentConfig.hasImpressionsProgressBar && this.hasImpressionsGoal;
      }
      return false;
    },
    showTacticSummaryData(): boolean {
      if (this.componentConfig) {
        return this.componentConfig.useTacticSummaryData;
      }
      return false;
    },
    hasImpressionsGoal(): boolean {
      return this.campaignDetails?.ImpressionGoal;
    },
    progressBarValues(): object {
      if (!this.campaignDetails) return {};
      const impressions = this.campaignDetails['Impressions'] || 0;
      const goal = this.campaignDetails['ImpressionGoal'] || 0;
      const origin = this.campaignDetails['OriginImpressionGoal'] || 0;
      const percent = this.campaignDetails['ImpressionGoalPercent'] || 0;

      return {
        metricValue: impressions,
        metricTitle: 'imps',
        totalValue: goal,
        totalTitle: 'Goal',
        originImpressionGoal: origin,
        percent: percent,
      };
    },
    showLastUpdated(): boolean {
      if (this.$route.query?.viewCampaigns?.includes(',')) {
        return false;
      } else return true;
    },
    lastModifiedDate(): string {
      return utils.getLastModifiedDate(this).toString();
    },
    showMetrics(): boolean {
      return this.topMetrics?.length > 0;
    },
    bothMetricsAndProgBar(): boolean {
      return this.showImpressionsProgressBar && this.showMetrics;
    },
    primaryColor(): { color: string } {
      return { color: this.Theme.light.primary };
    },
    lastUpdatedToolTip(): string {
      return utils.getTooltipsFromMetric('lastupdated')?.message;
    },
    validFeedSource(): boolean {
      // DASH-4133: show for audio tactic on XANDR campaigns
      if (!this.selectedCampaigns) return false;
      if (!(this.$store.state.customer.currentNavTab === 'audio')) return false;

      const allowedFeedSources = new Set();
      allowedFeedSources.add('xandr');

      // get list of all feed sources in selected campaign
      const selectedFeedSources = this.selectedCampaigns.reduce((acc, campaign) => {
        if (campaign.FeedSource) {
          if (Array.isArray(campaign.FeedSource)) {
            for (const f of campaign.FeedSource) {
              acc.add(f);
            }
          } else if (typeof campaign.FeedSource === 'string') {
            acc.add(campaign.FeedSource?.toLowerCase());
          } else {
            // eslint-disable-next-line no-console
            console.log('unexpected validFeedSource', campaign.FeedSource, typeof campaign.FeedSource);
          }
        }
        return acc;
      }, new Set());

      // check if any of the selected campaigns has a valid feed source
      for (const feedSource of selectedFeedSources) {
        if (allowedFeedSources.has(feedSource)) {
          return true;
        }
      }
      return false;
    },
    tacticNames(): string {
      if (this.selectedCampaigns.length === 1) {
        let list = [];
        if (this.isExporting) {
          list = this.selectedCampaigns[0].Tactics;
        } else {
          const details = utils.campaignDetailsById(this)[this.selectedCampaigns[0].id];
          if (!details) {
            return '';
          }
          list = details.Tactics;
        }
        if (!Array.isArray(list) || list.length === 0) {
          return '';
        }
        return list
          .map((tactic: string) => {
            return utils.getTacticName(tactic);
          })
          .join(', ');
      } else {
        if (!this.campaignDetails) {
          return '';
        }
        return utils.getTacticName(this.campaignDetails.AnalyticsType);
      }
    },
    rangeValue(): string {
      const params = { ...this.$store.state.customer.selection };
      const daterange = params.daterange;

      if (daterange === 'customRange') {
        return `Goal for Date Range`;
      }
      return `${this.dateRangeSelected} Goal`;
    },
    isShowImpressionGoal(): boolean {
      return this.totalValue > 100;
    },
  },
  methods: {
    formatDate(date: string): string {
      try {
        if (date && date.length > 0 && date !== 'undefined' && date !== 'noEndDate') {
          const parsedDate = utils.normalizeDate(date);
          return format(parsedDate, 'MMM d, yyyy');
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('campaign date', date, err);
      }
      return '';
    },
    formatValue(metric): string | number {
      let value = this.campaignDetails[metric.key];
      if (value === undefined) return 0;
      // showing full value here for now.
      // if (typeof value === 'number' && value > 999999) {
      //   return utils.formatValueEstimate(value);
      // }
      if (
        (typeof value === 'string' && value?.toLowerCase() === 'unknown') ||
        (typeof value === 'string' && value?.toLowerCase() === 'other')
      )
        return 0;
      if (this.componentConfig.dataSource === 'TV2OTTTotal' && ['CPM', 'CostPerResponse'].includes(metric.key)) {
        value = `$${value}`;
      }
      return utils.formatNumberWithCommas(value);
    },
    getTooltips({ key }: { title: string; value: string; key: string }): Array<Tooltip> {
      // special case for FBAds.
      if (
        this.componentConfig.dataSource === 'SOCIAL.GeoPerformanceImpression' &&
        key?.toLowerCase() === 'clickthrough'
      )
        key = 'socialctr';
      if (this.componentConfig.dataSource === 'SOCIAL.GeoPerformanceImpression' && key?.toLowerCase() === 'clicks')
        key = 'socialclicks';
      if (this.componentConfig.dataSource === 'VIDEO' && key?.toLowerCase() === 'clickthrough') key = 'digitalvideoctr';
      // special cases for TV2
      if (this.componentConfig.dataSource === 'TV2OTTTotal' && key?.toLowerCase() === 'spendval') {
        key = 'tvsquaredspendval';
      }
      if (this.componentConfig.dataSource === 'TV2OTTTotal' && key?.toLowerCase() === 'impressions') {
        key = 'tvsquaredimps';
      }
      return [utils.getTooltipsFromMetric(key)];
    },
    formatDateRange(inputDate: string): string {
      const date = new Date(inputDate);
      const options = { month: 'short', day: '2-digit', year: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);

      return formattedDate;
    },
    switchDateRange(newValue) {
      this.metricOverTotal = this.calcMetricOverTotal(newValue);
    },
    capitalizeWords(str: string): string {
      return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    },
    calcMetricOverTotal(impressionProgressValues) {
      let metric = this.progressBarValues.metricValue;
      let total = impressionProgressValues.totalValue || 0;
      let percent;

      if (!impressionProgressValues.percent) {
        // calculate percent if not given
        if (typeof metric === 'string') {
          metric = parseFloat(impressionProgressValues.metricValue.replace(/[!@#$%^&*]/g, ''));
        }
        if (typeof total === 'string') {
          total = parseFloat(impressionProgressValues.totalValue.replace(/[!@#$%^&*]/g, ''));
        }

        if (total === 0) {
          percent = metric === 0 ? '0%' : '100%';
        } else {
          percent = `${((metric / total) * 100).toFixed(0)}%`;
        }
      } else {
        percent = impressionProgressValues.percent;
      }

      if (!this.isExporting) {
        const exportState = this.$store.getters.exportState || {};
        exportState.impressionPercentForSelectedRange = percent;
        this.$store.dispatch('setExportState', exportState);
      }

      return percent;
    },
    calcDateRangeSelected(): string {
      const params = { ...this.$store.state.customer.selection };
      const daterange = params.daterange;

      if (daterange === 'customRange') {
        const startDate = this.isExporting
          ? this.formatDateRange(this.$store.state.customer.exportingData.adData.StartDate)
          : this.formatDateRange(this.$store.state.customer.campaignAdPerformance.StartDate);
        const endDate = this.isExporting
          ? this.formatDateRange(this.$store.state.customer.exportingData.adData.EndDate)
          : this.formatDateRange(this.$store.state.customer.campaignAdPerformance.EndDate);
        return `${startDate} - ${endDate}`;
      }

      const dateRangeList = this.isExporting
        ? this.$store.state.customer.exportingData?.campaigns.Campaign[0].DateRanges
        : this.$store.state.customer.campaigns.campaignlist[0].DateRanges;
      const range = dateRangeList?.find(item => item.RangeKey === daterange);

      if (range) {
        return this.capitalizeWords(range.RangeName);
      }

      const selectedRange = mappedRange(daterange, summaryDaterangeDefault);

      return selectedRange ? selectedRange.RangeName : 'All Time';
    },
  },
});
