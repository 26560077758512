
import Vue from 'vue';
import utils from '../../../../util';
import { Campaign } from '../../../../types/filters';
import Tooltips from '../tooltip/chartTooltip.vue';
import { Tooltip } from '../../../../../../../shared/dashboardLayouts/layout-components/types/layoutTypes';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import NoChartData from '../no-data/NoChartData.vue';

interface Stat {
  title: string;
  value: string;
  percent: string;
  property: string;
}

export default Vue.extend({
  inheritAttrs: false,
  name: 'campaignStats',
  props: [
    'sectionConfig',
    'componentConfig',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'componentHeight',
  ],
  components: {
    Tooltips,
    EditModuleBtn,
    NoChartData,
  },
  data: (): {} => ({}),
  mounted(): void {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.details.length > 0) {
          this.$emit('rendered', { empty: false });
        } else {
          this.$emit('rendered', { empty: true });
        }
      }, 10);
    });
  },
  computed: {
    details(): Array<object> | null {
      let details;
      if (this.isExporting) {
        if (Array.isArray(this.exportData.adData.CampaignList) && this.exportData.adData.CampaignList.length > 0) {
          details = this.exportData.adData.CampaignList;
        } else if (
          Array.isArray(this.exportData.advertiser.CampaignList) &&
          this.exportData.advertiser.CampaignList.length > 0
        ) {
          details = this.exportData.advertiser.CampaignList;
        }
      } else {
        details = this.campaignIds.reduce((all: Array<object>, id: string) => {
          const detail = utils.campaignDetailsById(this)[id];
          if (detail) {
            all.push(detail);
          }
          return all;
        }, []);
      }
      if (!details) {
        return null;
      }
      return details;
    },
    campaignIds(): string | null {
      if (this.isExporting) {
        return this.exportData.campaignIds;
      }
      if (!this.$store.state.filters?.selectedCampaigns) return null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return this.$store.state.filters.selectedCampaigns.filter((c: any) => !!c).map((c: Campaign) => c.id);
    },
    statsData(): Stat[] {
      // NOTE: should make this more dynamic if ends up being used by more tactics
      let data;
      if (this.details.length > 0 && this.hasAllData) {
        const values = ['Audience', 'Views', 'Clicks'];
        const total = this.sum('Audience');
        data = values.map((value: string) => {
          if (!this.details[0] && !this.details[0][value]) return;
          const summedVal = this.sum(value);
          return {
            title: utils.headerNamesMap(value),
            value: this.formatNumber(summedVal),
            percent: `${((summedVal * 100) / total).toFixed(2)}%`,
            property: value,
          };
        });
        const CTVR = (this.sum('Clicks') / this.sum('Views')) * 100;
        data.push({
          title: utils.headerNamesMap('ClickToViewRate'),
          value: `${CTVR.toFixed(2)}%`,
          percent: `${CTVR.toFixed(2)}%`,
          property: 'ClickToViewRate',
        });
      }
      return data;
    },
    progressStyles(): { container: { background: string }; progress: { background: string } } {
      return {
        container: {
          background: '#eeeeee',
        },
        progress: {
          background: 'var(--c360-gradient-accent)',
        },
      };
    },
    loading(): boolean {
      return utils.isWaitingOnData(this) && this.$store.state.filters.selectedCampaigns;
    },
    hasAllData(): boolean {
      let hasAllData = false;
      hasAllData = this.details.reduce((hasAll: boolean, detail) => {
        hasAll = hasAll && detail && detail.Audience && detail.Views && detail.Clicks && detail.ClickToViewRate;
        return hasAll;
      }, true);
      return hasAllData;
    },
  },
  methods: {
    getTooltips(metric: string): Array<Tooltip> {
      // console.log('h', metric, [utils.getTooltipsFromMetric(metric)]);
      if (metric.toLowerCase() === 'views') {
        return [utils.getTooltipsFromMetric('emailviews')];
      }
      return [utils.getTooltipsFromMetric(metric)];
    },
    formatNumber(num: string | number): string {
      if (typeof num === 'string') {
        const noFormat = num.split('').includes('$') || num.split('').includes('%');
        if (noFormat) return `${num}`;
      }
      return utils.formatNumberWithCommas(parseInt(`${num}`));
    },
    formatFloatToPercent(num: string): string {
      return utils.formatFloatToPercent(num);
    },
    showItemPercent(title: string): boolean {
      return title !== 'Audience' && title !== 'ClickToViewRate';
    },
    getNewTitle(title: string): string {
      const titles = {
        Audience: 'Quantity (Audience)',
        Views: 'Opens/Views and Open/View Rate',
        Clicks: 'Clicks and Click Rate',
      };
      if (titles[title]) {
        return titles[title];
      }
      return title;
    },
    sum(property: string): number {
      const sum = this.details.reduce((sum: number, detail) => {
        if (parseFloat(detail[property])) sum += parseFloat(detail[property]);
        return sum;
      }, 0);
      return sum || 0;
    },
  },
});
