
import Vue from 'vue';
import { componentConfig, componentHeight } from './utils/configLineChart';
import { OrderService } from '../../../services/order-summary';
import { format, parseISO } from 'date-fns';
import analytics from '../../../mixins/analytics';

const orderService = new OrderService();

export default Vue.extend({
  name: 'OrderDetailsChart',
  data: (): {
    metrics: object[];
    loading: boolean;
    dateRange: string | null;
    componentConfig: any;
    componentHeight: string;
    localStorageName: string;
    dynamicLineKeys: string[];
    defaultDatePeriod: string;
    selectedDatePeriod: string;
  } => ({
    dateRange: null,
    metrics: [],
    loading: false,
    componentConfig,
    componentHeight,
    localStorageName: 'metric_full_primary',
    dynamicLineKeys: [],
    defaultDatePeriod: 'daily',
    selectedDatePeriod: 'daily',
  }),
  components: {
    LineChart: () => import('../../../pages/components/charts/line/lineChartFull.vue'),
  },
  mixins: [analytics],
  created() {
    this.setDefaultDateRange();
    this.getData();
  },
  computed: {
    metricsNames(): string[] {
      return this.componentConfig.fetchData.map(item => item.name);
    },
    singleOrderParams(): any {
      return this.$store.state.order.singleOrderParams;
    },
  },
  methods: {
    async getData() {
      await this.fetchDataItem();
    },
    setDefaultDateRange() {
      this.dateRange = 'thismonth';
    },
    async chageDataPeriod(item) {
      this.analyticTrack(
        this.trackValue.ORDERS_CHANGE_DELIVERED_IMPS,
        `Value: ${item}, Order ID: ${this.$route.query?.orderId}`,
      );
      this.selectedDatePeriod = item;
      await this.fetchDataItem(item);
    },
    async fetchDataItem(itemParam) {
      const item = itemParam || this.defaultDatePeriod;
      try {
        this.loading = true;
        const impressionsData = await orderService.getOrderImpressionsByTacticAndPeriod({
          period: item || 'daily',
          daterange: this.dateRange,
          advertiserId: this.$route.query?.id || 'N/A',
          orderId: this.$route.query?.orderId || 'N/A',
        });

        const dateFormat = item === 'monthly' ? 'MMM yyy' : 'M/dd/yy';

        let itemsLimit = 0;
        switch (item) {
          case 'daily':
            itemsLimit = 90;
            break;
          case 'weekly':
            itemsLimit = 24;
            break;
          case 'monthly':
            itemsLimit = 12;
            break;
        }

        const tactics = [];
        const values = [];
        let xLegendValues = impressionsData.all.data.map(item => format(parseISO(item.date), dateFormat));
        const arraySeparator = xLegendValues.length - itemsLimit;
        xLegendValues = xLegendValues.slice(arraySeparator, xLegendValues.length);

        const percentageForAll = impressionsData['all'].percentageWithPrevious || null;

        for (const tactic in impressionsData) {
          let impressions = impressionsData[tactic].data.map(item => item.impressions);
          impressions = impressions.slice(arraySeparator, impressions.length);
          if (impressions.some(item => item !== 0)) {
            tactics.push(tactic);
            values.push(impressions);
          }
        }
        if (!values.length) {
          this.metrics = [];
          this.dynamicLineKeys = [];
          return;
        }
        const metricObject = {
          title: 'Delivered Impressions',
          percentage: percentageForAll,
          total: impressionsData?.total?.activeTotal,
          chartData: [xLegendValues, ...values],
          name: item,
        };
        this.metrics = [metricObject];
        this.dynamicLineKeys = tactics;
      } catch (error) {
        console.error(`Error fetching data for ${name}:`, error);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    singleOrderParams: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handler(newVal: any): void {
        if (!newVal?.daterange) return;

        this.dateRange = newVal.daterange;
        this.selectedRange = newVal.daterange;
        this.fetchDataItem();
      },
      deep: true,
    },
  },
});
