
import Vue from 'vue';
import utils from '../../../../util';

export default Vue.extend({
  name: 'MapDataConfig',
  props: ['componentData'],
  data() {
    return {
      selection: '',
      mapDataOptions: [],
    };
  },
  methods: {
    selectValue(): void {
      this.$emit('setOverride', 'mapShowDataFrom', this.selection);
    },
  },
  mounted(): void {
    this.selection = this.componentData.overrides?.mapShowDataFrom ?? 'geofencingLocations';
    this.mapDataOptions = utils.mapDataOptions;
  },
});
