
import Vue from 'vue';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import { C360Icon } from '@c360/ui';
import Tooltips from '../tooltip/chartTooltip.vue';

export default Vue.extend({
  components: { EditModuleBtn, Tooltips, C360Icon },
  inheritAttrs: false,
  props: {
    sectionConfig: Object,
    componentConfig: Object,
    title: String,
    domId: String,
    chartType: String,
    limitChartsEnoughData: Boolean,
    isExporting: Boolean,
    exportData: Object,
    exportContext: Object,
    isNewHome: Boolean,
    componentHeight: [String, Number],
    isBeeswax: Boolean,
  },
  computed: {
    noDataTooltips() {
      const tooltips = this.componentConfig?.noDataTooltips ?? [];
      return this.isBeeswax
        ? tooltips.filter(item => item?.key === 'Beeswax')
        : tooltips.filter(item => item?.key !== 'Beeswax');
    },
  },
});
