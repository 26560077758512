import { render, staticRenderFns } from "./share.vue?vue&type=template&id=0fc27d20&scoped=true"
import script from "./share.vue?vue&type=script&lang=ts"
export * from "./share.vue?vue&type=script&lang=ts"
import style0 from "./share.vue?vue&type=style&index=0&id=0fc27d20&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fc27d20",
  null
  
)

export default component.exports