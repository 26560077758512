
import utils from '../../../../util';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import Tooltips from '../tooltip/chartTooltip.vue';
import NewExportDownload from '../buttons/newExportDownload.vue';
import { C360Icon } from '@c360/ui';

export default {
  name: 'BenchmarksChart',
  props: ['sectionConfig', 'componentConfig', 'title', 'dataSource', 'isExporting', 'exportData', 'componentHeight'],
  components: {
    Tooltips,
    EditModuleBtn,
    C360Icon,
    NewExportDownload,
  },
  data() {
    return {};
  },
  watch: {
    async $route() {
      if (this.isShared) {
        return;
      } else {
        await this.fetchData();
      }
    },
  },
  computed: {
    isShared(): boolean {
      return this.$store.state.customer?.sharedDashboard;
    },
    loading(): boolean {
      return this.$store.state.performance?.isCampaignKpiLoading;
    },
    hasEnoughData(): boolean {
      if (this.$store.state.layoutEditor.editMode) return true;
      return (
        this.currentMetrics &&
        this.averageMetrics &&
        this.mappedMetrics &&
        this.mappedMetrics.length > 0 &&
        Object.keys(this.currentMetrics).length > 0 &&
        Object.keys(this.averageMetrics).length > 0
      );
    },
    averageMetrics(): Array<string> {
      return this.$store.state.performance?.campaignKpi || [];
    },
    availableMetrics(): Array<string> {
      return this.componentConfig?.legendKeys;
    },
    tacticName(): string {
      const tab = this.isShared
        ? this.$store.state.customer.sharedSelection?.tab?.toUpperCase()
        : this.$route.query?.tab?.toUpperCase();
      return tab === 'EMAILSI' ? 'SITEIMPACT' : tab;
    },
    currentMetrics(): object {
      return utils.adDataForKey(this, `${this.tacticName.toUpperCase()}Total`);
    },
    mappedMetrics() {
      // Determine keys for average and current with exceptions
      return this.availableMetrics
        .map(metric => {
          const avgKey = metric?.toLowerCase();
          let currentKey = metric;
          let formatedMetricName = '';

          if (metric === 'VCR' && this.tacticName !== 'GOOGLEVIDEO') {
            currentKey = 'CompletionRate';
          }
          if (metric === 'CVR' && this.tacticName === 'SEM') {
            currentKey = 'ConversionRate';
          }
          if (metric === 'CTR' && this.tacticName !== 'OTT') {
            currentKey = 'ClickThrough';
          }
          if (metric === 'LINK_CTR') {
            currentKey = 'LinkClickThrough';
            formatedMetricName = 'Link CTR';
          }
          if (metric === 'CLICK_TO_VIEW_RATE') {
            currentKey = 'ClickToViewRate';
            formatedMetricName = 'Click to View Rate';
          }
          if (metric === 'CLICK_TO_VIEW_RATE' && this.tacticName === 'GOOGLEVIDEO') {
            currentKey = 'ViewRate';
            formatedMetricName = 'View Rate';
          }
          if (metric === 'OPEN_RATE') {
            currentKey = 'OpenRate';
            formatedMetricName = 'Open Rate';
          }
          if (metric === 'CLICK_RATE') {
            currentKey = 'ClickRate';
            formatedMetricName = 'Click Rate';
          }
          if (metric === 'CPC') {
            currentKey = 'CostPerClick';
            formatedMetricName = 'CPC';
          }
          // Ensure avgValue and currValue are strings (default to "0" if no data)
          const avgValue = (this.averageMetrics[avgKey] ?? '0').toString();
          const currValue = (this.currentMetrics[currentKey] ?? '0').toString();

          // For the CPC metric, do not check for '%'
          const isCurrency = metric === 'CPC';
          const isPercentage = !isCurrency && currValue.includes('%');

          // Determine the number of decimal places for average and benchmark:
          // 4 for CVR, 2 for others
          const decimalPlaces = metric === 'CVR' ? 4 : 2;

          // Convert strings to numbers (remove '%' if present)
          const parsedAvg = parseFloat(avgValue.replace('%', ''));
          const parsedCurr = parseFloat(currValue.replace('%', ''));

          // Apply rounding rules for average
          const formattedAvg = isCurrency
            ? `$${parsedAvg.toFixed(decimalPlaces)}`
            : isPercentage
            ? `${parsedAvg.toFixed(decimalPlaces)}%`
            : parsedAvg.toFixed(decimalPlaces);

          // For current, if its parsed value is 0, format it with appropriate sign;
          // otherwise, leave it unchanged.
          const formattedCurr = (parsedCurr === 0) ?
            (isCurrency
                ? `$${parsedCurr.toFixed(decimalPlaces)}`
                : isPercentage
                ? `${parsedCurr.toFixed(decimalPlaces)}%`
                : parsedCurr.toFixed(decimalPlaces)
            ) : currValue;

          // Calculate benchmark (difference) using calculateDifference function
          // which handles adding a '+' sign for positive differences.
          const formattedBenchmark = this.calculateDifference(formattedAvg, formattedCurr);

          // Calculate benchmark status for the metric
          let benchmarkStatus = '';
          if (this.tacticName === 'GOOGLEVIDEO') {
            benchmarkStatus = this.getBenchmarkStatus('CLICK_TO_VIEW_RATE', parsedAvg, parsedCurr);
          } else {
            benchmarkStatus = this.getBenchmarkStatus(metric, parsedAvg, parsedCurr);
          }

          // Get styles via a separate function
          const { outerStyle, innerStyle, roundStyle } = this.getStyleForBenchmark(benchmarkStatus);

          return {
            metric: formatedMetricName || metric,
            average: formattedAvg,
            current: formattedCurr,
            benchmark: formattedBenchmark,
            benchmarkStatus,
            outerStyle,
            innerStyle,
            roundStyle,
          };
        })
        .filter(item => {
          // For filtering, remove '%' and '$' and compare numerical values
          const avgStr = item.average?.toString() || '';
          const currStr = item.current?.toString() || '';
          const avgNum = parseFloat(avgStr.replace('%', '').replace('$', ''));
          const currNum = parseFloat(currStr.replace('%', '').replace('$', ''));

          if (item.metric === 'CPC' && currNum === 0) {
            return false;
          }
          if (item.metric === 'CVR' && currNum === 0) {
            return false;
          }
          // Exclude objects where both values are 0
          return !(avgNum === 0 && currNum === 0);
        });
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData(): Promise<void> {
      const payload = this.isShared
        ? {
            advertiserId: this.$store.state.customer.sharedSelection?.aid || '',
            campaignId: this.$store.state.customer.sharedSelection?.viewCampaigns,
            type: this.tacticName,
            token: this.$store.state.customer.user.token,
          }
        : {
            advertiserId: this.$route.query?.id || '',
            campaignId: this.$route.query?.viewCampaigns,
            type: this.tacticName,
          };
      try {
        await this.$store.dispatch('performance/getCampaignKpi', {
          ...payload,
        });
      } catch (error) {
        console.error(error);
      }
    },
    calculateDifference(average, current) {
      const currentStr = current.toString();
      const averageStr = average.toString();
      const isPercentage = currentStr.includes('%') || averageStr.includes('%');
      const isCurrency = currentStr.includes('$') || averageStr.includes('$');
      // Remove '%' and '$' from average and current strings before parsing
      const avg = parseFloat(averageStr.replace('%', '').replace('$', ''));
      const curr = parseFloat(currentStr.replace('%', '').replace('$', ''));
      const diff = curr - avg;

      if (isPercentage) {
        // diff.toFixed(2) will include the negative sign if diff is negative
        const sign = diff > 0 ? '+' : '';
        return `${sign}${diff.toFixed(2)}%`;
      } else if (isCurrency) {
        // If diff is negative, prepend '-' then '$' then absolute value
        if (diff < 0) {
          return `-$${Math.abs(diff).toFixed(2)}`;
        } else {
          return `+$${diff.toFixed(2)}`;
        }
      } else {
        const sign = diff > 0 ? '+' : '';
        return `${sign}${diff.toFixed(2)}`;
      }
    },
    getBenchmarkStatus(metric, average, current) {
      const diff = current - average;
      // For VCR:
      // Good: current >= average
      // Critical: below average by more than 10.00%
      // Average: below average but not more than 10.00%
      if (metric === 'VCR') {
        if (diff >= 0) {
          return 'Good';
        } else if (Math.abs(diff) > 10.0) {
          return 'Critical';
        } else {
          return 'Average';
        }
      }
      // For CTR:
      // Critical: current == 0 or below average by more than 0.10%
      // Good: current >= average
      // Average: below average but not more than 0.10%
      else if (metric === 'CTR') {
        if (current === 0) {
          return 'Critical';
        } else if (diff >= 0) {
          return 'Good';
        } else if (Math.abs(diff) > 0.1) {
          return 'Critical';
        } else {
          return 'Average';
        }
      }
      // For LINK_CTR:
      // Critical: below average by more than 1.00%
      // Good: below average by less than or equal to 1.00%
      // Average: equal to or above average
      else if (metric === 'LINK_CTR') {
        if (diff >= 0) {
          return 'Average';
        } else if (Math.abs(diff) <= 1.0) {
          return 'Good';
        } else {
          return 'Critical';
        }
      }
      // For CVR:
      // Critical: if current == 0
      // Average: if current > 0 but less than average
      // Good: if current >= average
      else if (metric === 'CVR') {
        if (current === 0) {
          return 'Critical';
        } else if (current < average) {
          return 'Average';
        } else {
          return 'Good';
        }
      }
      // For CPC:
      // Good: if current <= average
      // Critical: if current is above average by more than $1.00
      // Average: if current is above average but the difference is not more than $1.00
      else if (metric === 'CPC') {
        if (current <= average) {
          return 'Good';
        } else if (diff > 1.0) {
          return 'Critical';
        } else {
          return 'Average';
        }
      }
      // For OpenRate:
      // Critical: below average by more than 5.00%
      // Average: below average by less than or equal to 5.00%
      // Good: equal to or above average
      else if (metric === 'OPEN_RATE') {
        if (diff >= 0) {
          return 'Good';
        } else if (Math.abs(diff) > 5.0) {
          return 'Critical';
        } else {
          return 'Average';
        }
      }
      // For ClickRate:
      // Critical: below average by more than 1.00%
      // Average: below average by less than or equal to 1.00%
      // Good: equal to or above average
      else if (metric === 'CLICK_RATE') {
        if (diff >= 0) {
          return 'Good';
        } else if (Math.abs(diff) > 1.0) {
          return 'Critical';
        } else {
          return 'Average';
        }
      }
      // For ClickToViewRate:
      // Critical: below average by more than 5.00%
      // Average: below average by less than or equal to 5.00%
      // Good: equal to or above average
      else if (metric === 'CLICK_TO_VIEW_RATE') {
        if (diff >= 0) {
          return 'Good';
        } else if (Math.abs(diff) > 5.0) {
          return 'Critical';
        } else {
          return 'Average';
        }
      }

      return 'N/A';
    },
    getStyleForBenchmark(benchmarkStatus) {
      const status = benchmarkStatus.toLowerCase();
      let computedOuterStyle = {};
      let computedInnerStyle = {};
      let computedRoundStyle = {};

      if (status === 'critical') {
        computedOuterStyle = { backgroundColor: '#e127191a' };
        computedInnerStyle = { backgroundColor: '#e127191a' };
        computedRoundStyle = { color: '#e12719' };
      } else if (status === 'average') {
        computedOuterStyle = { backgroundColor: '#ffb8001a' };
        computedInnerStyle = { backgroundColor: '#ffb8001a' };
        computedRoundStyle = { color: '#ffb800' };
      } else if (status === 'good') {
        computedOuterStyle = { backgroundColor: '#3fbc561a' };
        computedInnerStyle = { backgroundColor: '#3fbc561a' };
        computedRoundStyle = { color: '#3FBC56' };
      }

      return {
        outerStyle: computedOuterStyle,
        innerStyle: computedInnerStyle,
        roundStyle: computedRoundStyle,
      };
    },
  },
};
