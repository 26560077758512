
import Vue from 'vue';
import analytics from '../../../../mixins/analytics';
import { impressionsByClientTooltips } from './utils/constants';

export default Vue.extend({
  name: 'ImpressionsRow',
  props: ['item', 'dateRange', 'selectedRange'],
  mixins: [analytics] as any,
  data() {
    return {
      tooltips: impressionsByClientTooltips,
    };
  },
  computed: {
    showPrevPeriod(): boolean {
      return this.dateRange !== 'alltime';
    },
    previousProgress(): number {
      const percentage = Math.floor((this.item?.prevImpressions / this.item?.impressionsGoal) * 100);
      return percentage;
    },
  },
  methods: {
    numberFormat(value) {
      return new Intl.NumberFormat().format(value);
    },
    pushToAdvertiser() {
      if (!this.item.advertiserId) return;
      this.analyticTrack(
        this.trackValue.POST_LOGIN,
        `Go to Advertiser "${this.item.advertiserName || this.item.advertiserId}"`,
      );
      const queryParams = { id: this.item.advertiserId, tab: 'orderlist' };
      this.$router.push({ query: queryParams });
    },
  },
});
