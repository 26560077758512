
import Vue from 'vue';
import { Campaign } from '../../../types/filters';
import util from '../../../util';
import analytics from '../../../mixins/analytics';
import CampaignFriendlyNameEdit from './campaignFriendlyNameEdit.vue';
import CampaignDateSelector from '../toolbar/date-selection/campaignDateSelector.vue';
import CampaignLists from './campaignNavLists.vue';
import CampaignMarkup from './campaignSpending/campaignSpending.vue';
import EditCampaignModal from './editCampaign/EditCampaignModal.vue';
import CampaignSummaryNarrativeModal from './campaignSummaryNarrative/CampaignSummaryNarrativeModal.vue';
import Tabs from './tabs/tabs.vue';
import { C360Icon } from '@c360/ui';
import { checkPermissions } from '@point/auth';
import utils from '@/util';

let mutations: () => void;

export default Vue.extend({
  name: 'campaignNav',
  inheritAttrs: false,
  props: ['sectionConfig', 'componentConfig'],
  mixins: [analytics],
  components: {
    CampaignFriendlyNameEdit,
    CampaignDateSelector,
    CampaignLists,
    CampaignMarkup,
    C360Icon,
    EditCampaignModal,
    CampaignSummaryNarrativeModal,
    Tabs,
  },
  data(): {
    error: string | null;
    showCampaignNameEdit: boolean;
    campaignViewToggle: number;
    showMenu: boolean;
    localSelectedCampaigns: Array<Campaign> | null;
    searchText: string | undefined;
    viewSelection: string;
    campaignList: object[] | null;
    fullCampaignList?: object[] | null;
    filteredList: object[] | null;
    hasMarkupRights: boolean;
    isNavBarCollapsed: boolean;
    campaignNameCopied: boolean;
    selectAllChecked: boolean;
    showEditCampaignModal: boolean;
    showCapmaignNarrativeModal: boolean;
    editCampaignError: string | null;
  } {
    return {
      error: null,
      showCampaignNameEdit: false,
      campaignViewToggle: 0,
      showMenu: false,
      localSelectedCampaigns: null,
      searchText: undefined,
      viewSelection: 'summary',
      campaignList: null,
      filteredList: null,
      hasMarkupRights: false,
      isNavBarCollapsed: false,
      campaignNameCopied: false,
      selectAllChecked: false,
      showEditCampaignModal: false,
      showCapmaignNarrativeModal: false,
      editCampaignError: null,
    };
  },
  computed: {
    showMultipleCampaignDataCombined(): boolean {
      return this.selectedCampaigns && this.selectedCampaigns.length > 1;
    },
    hasEditOrderCampaignsPermission(): boolean {
      return utils.hasRight(this.$store.state.customer.user, ['PERMISSION_EDIT_ORDER_CAMPAIGNS']);
    },
    hasReadCampaignsAISummaryNarrativePermission(): boolean {
      return utils.hasRight(this.$store.state.customer.user, ['CAMPAIGN_SUMMARY_NARRATIVE_READ']);
    },
    campaignHasPIOID(): boolean {
      return !!this.$store.state.customer.campaignAdPerformance?.CampaignList?.[0]?.PIOID;
    },
    showEditCampaignButton(): boolean {
      return this.hasEditOrderCampaignsPermission && this.campaignHasPIOID;
    },
    showCampaignNarrativeButton(): boolean {
      // DASH-5677: prevent for specific tactitcs
      const forbiddenList = ['ga', 'linear', 'broadcast'];

      return (
        this.selectedCampaigns?.length < 2 &&
        !this.$route.query?.summaryView &&
        !forbiddenList.includes(this.sectionConfig?.id) &&
        this.hasReadCampaignsAISummaryNarrativePermission
      );
    },
    isMobile(): boolean {
      if (this.showEditCampaignButton && window.innerWidth < 1570) return true;
      return this.$vuetify.breakpoint.mdAndDown || this.isNavBarCollapsed;
    },
    loaded(): boolean {
      // return this.$store.state.customer.networkActivityCounter < 1;
      return !this.$store.getters.networkActivity;
    },
    // Temporary solution for new TAB Attribution epic
    showTabs(): boolean {
      const isXandr = this.singleCampaignFeedSource?.toLowerCase() === 'xandr';
      const layoutConfig = this.$store.state.customer?.dynamicLayout?.layoutCustomizations?.config;
      return (
        isXandr &&
        layoutConfig?.shouldUsingTabs &&
        this.$route.query.tab === 'display' &&
        this.$store.state?.customer?.user?.is_superuser
      );
    },
    isLoadingCampaignAdPerformance(): boolean {
      return this.$store.state.customer.loadingCampaignAdPerformance;
    },
    showCampaignNav(): boolean {
      // Temporary solution for new TAB Attribution epic
      if (this.showTabs) {
        return true;
      }
      if (['summary', 'orderlist'].includes(this.sectionConfig?.id?.toLowerCase())) {
        return false;
      }
      return this.selectedCampaigns;
    },
    selectedCampaigns(): Array<Campaign> {
      return this.$store.state.filters.selectedCampaigns;
    },
    isSingleCampaignSelect(): boolean {
      const id = this.sectionConfig?.id?.toLowerCase();
      return id === 'gamvideo' || id === 'gamdisplay';
    },
    selectedCampaignIndex(): number {
      if (!this.selectedCampaigns || this.selectedCampaigns.length < 1) {
        return -1;
      }
      // find the index of currently selected campaign
      return this.campaignList.findIndex((campaign: Campaign) => {
        return this.selectedCampaigns?.[0]?.id === campaign.id;
      });
    },
    campaignPageCount(): string {
      // "Campaign x of y"
      return `Campaign ${this.selectedCampaignIndex + 1} of ${this.campaignList?.length}`;
    },
    isShared(): boolean {
      return this.$store.state.customer?.sharedDashboard;
    },
    hideDatePicker(): boolean {
      // special case for DA, needs to be at advertiser level. probably should come from the data.
      return (
        this.isShared && this.$store.state.customer.sharedSelection?.agencyPartner?.toLowerCase() === 'dealer_alchemist'
      );
    },
    campaignDetailsById(): object {
      return util.campaignDetailsById(this);
    },
    campaignTitle(): string {
      return this.selectedCampaigns?.[0]?.name || this.selectedCampaigns?.[0]?.FriendlyName;
    },
    tacticTitle(): string {
      return this.sectionConfig?.title;
    },
    allowSingleCampaignView(): boolean {
      return util.allowSingleCampaignView(this);
    },
    currentSectionId(): string {
      return util.dataKeyBySectionIdMap(this.$store.state.customer.currentSection.id);
    },
    sharedTitle(): string | null {
      if (!this.campaignList?.length) return null;
      const campaignTitle = this.campaignList?.[0]?.FriendlyName || this.campaignList?.[0]?.name;
      return this.campaignList?.length > 1 ? 'Multiple campaign data combined' : campaignTitle;
    },
    hasTacticSummary(): boolean {
      let hasSummary = false;
      const hasViewArr = util.adDataForKey(this, 'HasSummaryViewFor');
      const tacticKey = util.dataKeyBySectionIdMap(
        this.$route.query.tab || this.$store.state.customer?.sharedSelection?.tab,
      ); // tab doesn't exist in share

      if (hasViewArr && tacticKey) {
        hasSummary = hasViewArr.includes(tacticKey);
      }
      return hasSummary;
    },
    tacticName(): string {
      return util.getTacticName(this.$route.query.tab || this.$store.state.customer?.sharedSelection?.tab);
    },
    summarySelected(): boolean {
      if (this.isShared)
        return this.$store.state.customer?.sharedSelection?.summaryView || this.$route.query.summaryView;
      return this.$route.query.summaryView;
    },
    showNavBtns(): boolean {
      return (
        this.campaignList &&
        this.campaignList.length > 1 &&
        this.selectedCampaigns &&
        this.selectedCampaigns.length === 1 &&
        this.allowSingleCampaignView &&
        this.selectedCampaignIndex > -1 &&
        !this.summarySelected
      );
    },
    allowAll(): boolean {
      // only allow all if tactic has 200 or less campaigns.
      return this.campaignList?.length <= 200;
    },
    singleCampaignFeedSource(): string {
      return this.selectedCampaigns?.[0]?.FeedSource;
    },
    isArchived(): boolean {
      return this.selectedCampaigns?.[0]?.Archived;
    },
    filteredCampaigns() {
      if (this.searchText?.length < 2 || !this.searchText) {
        return this.campaignList;
      }
      const regSearch = this.getEscapedRegExp(this.searchText);
      return this.campaignList.filter(c => c.FriendlyName.search(regSearch) >= 0);
    },
    isDemoAdvertiser() {
      const advertiser = this.$store.state.advertiser?.advertiserInfo?.data?.advertiser;
      return advertiser === 'Demo Test Advertiser' || advertiser === 'Demo Advertiser';
    },
    isHiddenElementsForDemoAdvertiser() {
      return this.isDemoAdvertiser && !this.$store.state?.customer?.user?.is_superuser;
    },
  },
  watch: {
    '$route.query.tab': {
      handler(): void {
        // maybe need some better logic for this call
        this.$store.dispatch('setTacticPageView', 'individual');
        this.searchText = undefined;
        if (this.campaignList && this.campaignList.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
          const { summaryView, viewCampaignType, viewCampaigns, ...cleanQuery } = this.$route.query;
          // const campaign = this.campaignList[0];
          // I don't think we want the viewCampaignType param on the summary page.
          if (!this.allowSingleCampaignView && this.currentSectionId?.toLowerCase() !== 'summary') {
            // campaign select is hidden, combine all campaigns of current section type
            this.$router.push({
              query: { ...cleanQuery, viewCampaignType: this.currentSectionId },
            });
          }
        }
      },
    },
    '$store.state.customer.campaigns.campaignlist': {
      handler(): void {
        const campsInStore = Array.isArray(this.$store.state.customer?.campaigns?.campaignlist);
        if (!this.campaignList && campsInStore) {
          this.setCampaignList();
        }
      },
    },
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutations = this.$store.subscribe((mutation: any) => {
      switch (mutation.type) {
        case 'SET_CAMPAIGNS':
          // if we received the campaign data while this view is already active,
          // if we don't have a viewCampaigns parameter, select the first available campaign
          if (
            !this.$route.query.viewCampaigns &&
            mutation.payload.loaded &&
            this.campaignList &&
            this.campaignList.length > 0
          ) {
            const campaign = this.campaignList?.[0];
            this.$store.dispatch('setSelectedCampaigns', [campaign]);
          }
          break;
        default:
          break;
      }
    });
    this.setCampaignList();

    // no markup for shared pages.
    if (!this.isShared) this.checkMarkupRights();

    setTimeout(this.initNav, 100);
    this.observeWidth();
  },
  beforeDestroy(): void {
    mutations();
  },
  methods: {
    onEditCampaignError(): void {
      this.editCampaignError = 'There was an error updating the campaign.';
      setTimeout(() => {
        this.editCampaignError = null;
      }, 5000);
    },
    toggleCampaignFriendlyNameEdit(state: boolean) {
      if (this.isShared) {
        return;
      }
      this.showCampaignNameEdit = state;
    },
    toggleEditCampaignModal(state: boolean) {
      this.showEditCampaignModal = state;
    },
    toggleCapmaignNarrativeModal(state: boolean) {
      this.showCapmaignNarrativeModal = state;

      if (state) {
        this.analyticTrack(this.trackValue.AI_SUMMARY_NARRATIVE_OPEN_MODAL);
      }
    },
    async copyOriginalCampaignName(campaign): Promise<void> {
      try {
        if (this.isHiddenElementsForDemoAdvertiser) {
          await navigator.clipboard.writeText(campaign.FriendlyName);
        } else {
          await navigator.clipboard.writeText(campaign.name);
        }
        this.campaignNameCopied = true;
      } catch (e) {
        console.error('Failed to copy: ', e);
      }
    },
    initNav(): void {
      if (!this.campaignList || (this.campaignList.length < 1 && !this.isShared)) return;
      let campaigns;
      if (this.allowSingleCampaignView && !this.$store.state.customer?.sharedSelection?.summaryView) {
        // SINGLE / MULTIPLE VIEW
        // see if there is at least one valid selected campaign
        if (this.$route.query.viewCampaigns) {
          campaigns = this.fullCampaignList.filter((x: { id: string }) =>
            this.selectedCampaigns?.some(c => c.id === x.id),
          );
          this.viewSelection = 'individual';
        }
        if (this.$route.query.viewCampaigns && (!campaigns || campaigns.length < 1)) {
          this.error = 'This campaign is not available or is an invalid campaign ID number';
        }
        // if no url param or no valid selections, default to first campaign in list
        else if (!this.$route.query.viewCampaigns || !campaigns || campaigns.length < 1) {
          campaigns = [this.campaignList?.[0]];
        }
        // set url param if not set yet
        if (!this.$route.query.viewCampaigns) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
          const { summaryView, viewCampaignType, ...cleanQuery } = this.$route.query;
          const query = { ...cleanQuery, viewCampaigns: campaigns?.[0]?.id };
          if (!summaryView) this.$router.replace({ query });
        }
      } else if (this.$store.state.customer?.sharedSelection?.summaryView) {
        // COMBINED VIEW

        const sectionId = this.$route.query.viewCampaignType || this.currentSectionId;
        campaigns = this.$store.state.customer.campaigns.campaignlist?.filter((c: Campaign) => {
          return c.CampaignType === this.currentSectionId;
        });
        // set url param if not set yet
        if (!this.$route.query.viewCampaignType) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
          const { summaryView, viewCampaigns, ...cleanQuery } = this.$route.query;
          const query = { ...cleanQuery, viewCampaignType: sectionId };
          if (!summaryView) this.$router.push({ query });
        }
      }

      if (!campaigns) {
        if (this.$route.query.tab !== 'summary') {
          // eslint-disable-next-line no-console
          console.log('campaignNav.initNav no campaigns');
        }
        return;
      }

      this.$store.dispatch('setSelectedCampaigns', campaigns).then(() => {
        setTimeout(() => {
          if (this.hasTacticSummary || this.$route.query?.summaryView) {
            const tactic = util.dataKeyBySectionIdMap(
              this.$route.query.tab || this.$store.state.customer?.sharedSelection?.tab,
            );
            // pass token if shared.
            const token = this.$store.state.customer?.sharedSelection?.etoken
              ? this.$store.state.customer?.sharedSelection?.etoken
              : null;
            this.$store
              .dispatch('getTacticSummary', {
                tactic,
                advertiserId: this.$route.query.id || this.$store.state.customer?.sharedSelection?.aid,
                token,
              })
              .then(() => {
                if (this.$route.query.summaryView || this.$store.state.customer?.sharedSelection?.summaryView) {
                  this.changeView('summary');
                }
              });
          }
        }, 500);
      });
      this.localSelectedCampaigns = campaigns;
    },
    loadFirstCampaign(): void {
      // remove the "viewCampaigns" query param which will trigger loading the first active campaign
      const query = { ...this.$route.query };
      delete query.viewCampaigns;
      this.$router.push({ query });
      this.error = null;
      this.$nextTick(() => {
        this.initNav();
      });
    },
    setCampaignList(): void {
      // fires on mounted and when $store.state.customer.campaigns.campaignlist changes
      if (!Array.isArray(this.$store.state.customer?.campaigns?.campaignlist)) {
        return;
      }

      const filterCampaigns = (c: Campaign): boolean => {
        if (this.sectionConfig?.id === 'ott') {
          // DASH-3091 filter out 'Amazon_Display' & 'Amazon_Video' for OTT
          if (c.FriendlyName.includes('Amazon_Display') || c.FriendlyName.includes('Amazon_Video')) {
            return false;
          }
        }
        if (this.campaignViewToggle === 1) {
          // show only selected campaigns
          const isSelected = this.selectedCampaigns.some((camp: Campaign) => {
            return camp.id === c.id;
          });
          if (!isSelected) return false;
        }
        // if shared page, use only the shared campaigns
        if (this.isShared && this.$store.state.customer?.sharedCampaigns) {
          // show only selected campaigns
          const isSelected = this.$store.state.customer.sharedCampaigns.some((camp: Campaign) => {
            return camp.id === c.id;
          });
          if (!isSelected) return false;
        }

        if (!this.$store.getters.currentTabCampaignTypes.includes(c.CampaignType.toUpperCase())) {
          return false;
        }
        return true;
      };

      // Not using
      // const debugCampaign = (c: Campaign): string => {
      //   const x = this.campaignDetailsById[c.id];
      //   if (!x) {
      //     return `details not found for ${c.id}`;
      //   }
      //   const sX = x.StartDate;
      //   if (!sX) {
      //     return `StartDate not found for ${c.id}`;
      //   }
      //   const dX = parse(sX, 'M/d/yyyy pp', new Date());
      //   return `${format(dX, 'MM/d/yy hh:mm a')} ${dX.getTime()}`;
      // };
      const list = [...this.excludeArchivedCampaigns(this.$store.state.customer.campaigns.campaignlist)];
      const fullCampaignList = [...this.$store.state.customer.campaigns.campaignlist];
      this.fullCampaignList = util.sortByMostRecentDate(fullCampaignList.filter(filterCampaigns), 'Start_date');

      this.campaignList = util.sortByMostRecentDate(list.filter(filterCampaigns), 'Start_date'); // filtering by start date.
      this.filteredList = this.campaignList;
    },
    excludeArchivedCampaigns(campaignList) {
      return campaignList?.filter(c => !c?.Archived) || [];
    },
    filterList(): void {
      if (this.searchText?.length < 2 || !this.searchText) {
        // if no valid search, set to full list
        this.filteredList = this.campaignList;
      } else {
        // filter based on search text
        const regSearch = this.getEscapedRegExp(this.searchText);
        this.filteredList = this.campaignList.filter(c => {
          return c.FriendlyName.search(regSearch) >= 0;
        });
      }
    },
    navigateCampaigns(event: Event, direction: number, disabled: boolean): void {
      // stop propagation
      event.stopPropagation();
      if (disabled || !this.showNavBtns) {
        return;
      }
      if (this.selectedCampaignIndex === -1) {
        return;
      }
      const selectedIndex = this.selectedCampaignIndex;

      this.localSelectedCampaigns = []; // reset to avoid selecting multiple

      const event_name =
        direction > 0 ? this.trackValue.TRACK_CAMPAIGN_NAV_NEXT : this.trackValue.TRACK_CAMPAIGN_NAV_PREVIOUS;

      this.analyticTrack(this.trackValue.EVENT_NAV_CAMPAIGN, event_name);

      // try to select the next/previous campaign
      if (this.campaignList[selectedIndex + direction]) {
        this.selectCampaign(this.campaignList[selectedIndex + direction]);
      }
      // otherwise select the first campaign
      else this.selectCampaign(this.campaignList?.[0]);
    },
    selectAllCampaigns(): void {
      if (this.campaignList?.length > 0) {
        this.localSelectedCampaigns = [...this.campaignList];
        this.$store.dispatch('setSelectedCampaigns', this.localSelectedCampaigns);
        this.analyticTrack(this.trackValue.EVENT_NAV_CAMPAIGN, this.trackValue.TRACK_CAMPAIGN_NAV_SELECT_ALL);
        this.updateURL();
      }
    },
    deselectAllCampaigns(): void {
      if (this.campaignList?.length > 0) {
        this.localSelectedCampaigns = [this.campaignList?.[0]];
        this.$store.dispatch('setSelectedCampaigns', this.localSelectedCampaigns);
        this.analyticTrack(this.trackValue.EVENT_NAV_CAMPAIGN, this.trackValue.TRACK_CAMPAIGN_NAV_DESELECT_ALL);
        this.updateURL();
        this.selectAllChecked = false;
      }
    },
    selectCampaign(campaign: Campaign): void {
      if (!campaign || !campaign.id) return;

      // if there's only 1 selected, wipe the query parameter and select the first campaign
      if (this.localSelectedCampaigns?.length === 1 && this.localSelectedCampaigns?.[0]?.id === campaign.id) {
        if (!this.isShared) {
          const query = { ...this.$route.query };
          if (!query.viewCampaigns) return; // if no query parameter is set that means this is the default view. do nothing
          delete query.viewCampaigns;
          this.analyticTrack(this.trackValue.EVENT_NAV_CAMPAIGN, this.trackValue.TRACK_CAMPAIGN_NAV_SELECT_ONE);
          this.$router.push({ query });
        }
        this.localSelectedCampaigns = [this.campaignList?.[0]]; // select the first one
        return;
      }

      if (!this.localSelectedCampaigns) this.localSelectedCampaigns = []; // make sure we have an array

      if (this.localSelectedCampaigns.some(c => c.id === campaign.id)) {
        // remove campaign
        this.localSelectedCampaigns = this.localSelectedCampaigns.filter((c: Campaign) => c.id !== campaign.id);
      } else {
        this.localSelectedCampaigns.push(campaign); // add campaign
      }

      this.$store.dispatch('setSelectedCampaigns', this.localSelectedCampaigns);
      this.analyticTrack(this.trackValue.EVENT_NAV_CAMPAIGN, this.trackValue.TRACK_CAMPAIGN_NAV_SELECT_MULTIPLE);
      if (campaign && campaign.id !== this.$route.query.viewCampaigns) {
        this.updateURL();
        // turn off campaign name edit.
        this.toggleCampaignFriendlyNameEdit(false);
      }
    },
    updateURL(): void {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const { viewtab, summaryView, ...cleanQuery } = this.$route.query;
      const viewCampaigns = this.localSelectedCampaigns.map((c: Campaign) => c.id).join(',');
      this.$router.push({ query: { ...cleanQuery, viewCampaigns } });
    },
    changeView(view: string): void {
      if (view === 'summary') {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        const { summaryView, viewCampaignType, viewCampaigns, ...cleanQuery } = this.$route.query;
        if (!summaryView) {
          this.$router.push({ query: { ...cleanQuery, summaryView: true } });
        }

        setTimeout(() => {
          this.showMenu = false;
        }, 500);
      } else {
        this.updateURL();
      }
      this.viewSelection = view;
    },
    checkMarkupRights(): void {
      const hasPermissions = checkPermissions(
        this.$store.state.customer.user.accessToken,
        ['CAMPAIGN_MARKUP_READ'],
        false,
      );
      this.hasMarkupRights = !!this.sectionConfig?.markup?.length && hasPermissions;
    },
    // temporary solution until we have new C360 UI for this section
    observeWidth(): void {
      const resizeObserver = new ResizeObserver(() => {
        const limitation = this.$store.state.customer.selection.daterange === 'alltime' ? 1100 : 1200;
        if (x) {
          this.isNavBarCollapsed = x.clientWidth < limitation;
        }
      });
      const x = document.querySelector('.campaign-nav');
      if (x) {
        resizeObserver.observe(x);
      }
    },
    toggleSelectAll() {
      this.selectAllChecked = !this.selectAllChecked;
      if (this.selectAllChecked) {
        this.localSelectedCampaigns = this.filteredCampaigns.slice(0, 200);
      } else {
        this.localSelectedCampaigns = [this.campaignList?.[0]];
      }
      this.analyticTrack(
        this.trackValue.EVENT_NAV_CAMPAIGN,
        this.trackValue.TRACK_CAMPAIGN_NAV_TOGGLE_ONLY_MATCHING_CAMPAIGNS,
      );
      this.$store.dispatch('setSelectedCampaigns', this.localSelectedCampaigns);
      this.updateURL();
    },
    getEscapedRegExp(searchText, flags = 'i') {
      return new RegExp(searchText.replace(/[-/\^$*+?.()|[]{}]/g, '\$&'), flags);
    },
  },
});
