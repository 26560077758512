
import Vue from 'vue';
import { C360Icon } from '@c360/ui';
export default Vue.extend({
  props: ['geoData', 'isExporting'],
  components: {
    C360Icon,
  },
  data: (): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    headers: Array<any>;
    tab: null;
    itemsPerPage: number;
    currentPage: number;
  } => ({
    headers: [
      {
        text: 'Code',
        value: 'code',
        sortable: false,
      },
      {
        text: 'City',
        value: 'city',
        sortable: false,
      },
    ],
    tab: null,
    itemsPerPage: 7,
    currentPage: 1,
  }),
  computed: {
    totalPages(): number {
      return Math.ceil(this.geoData?.length / this.itemsPerPage);
    },
    pageText(): string {
      const totalItems = this.geoData?.length;
      const firstItem = (this.currentPage - 1) * this.itemsPerPage + 1;
      const lastItem = Math.min(this.currentPage * this.itemsPerPage, totalItems);

      return `${firstItem}-${lastItem} of ${totalItems}`;
    },
  },
  methods: {
    changePage(direction: number): void {
      if (direction === -1 && this.currentPage > 1) {
        this.currentPage -= 1;
      } else if (direction === 1 && this.currentPage < this.totalPages) {
        this.currentPage += 1;
      }
    },
  },
});
