
import utils from '../../util';
import { useLink } from '@c360/ui';
let mutations: () => void;

export default {
  data(): {
    toasting: boolean;
    refreshing: boolean;
    newBuildLoading: boolean;
    updateExists: boolean;
    newBuildNumber: string | null;
    dateCreated: number;
  } {
    return {
      toasting: false,
      refreshing: false,
      newBuildLoading: false,
      updateExists: false,
      newBuildNumber: null,
      dateCreated: new Date().getTime(),
    };
  },
  created(): void {
    document.addEventListener('swUpdated', this.showRefreshUI);
    document.addEventListener('swUpdateAvailable', this.newBuildEvent);
    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', event => {
        if (event.data) {
          if (event.data.version && event.data.version.length > 10) {
            this.newBuildNumber = `v1.${event.data.version.substr(3, 6)}`;
          }
        }
      });
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) {
          return;
        }
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  mounted(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutations = this.$store.subscribe((mutation: any) => {
      switch (mutation.type) {
        case 'SET_ERROR':
          this.toasting = mutation.payload !== null;
          break;
        default:
          break;
      }
    });
  },
  beforeDestroy(): void {
    mutations();
    document.removeEventListener('swUpdated', this.showRefreshUI);
    document.removeEventListener('swUpdateAvailable', this.newBuildEvent);
  },
  computed: {
    toastTimeout(): number {
      const eType = this.$store.state.customer?.error?.errorType;
      switch (eType) {
        case 'exportError':
          return 30 * 1000;
        default:
          break;
      }
      return 5 * 1000;
    },
    errorType(): string | null {
      return this.$store.state.customer?.error?.errorType || null;
    },
    errorMessage(): string | null {
      if (this.$store.state.customer.error) {
        if (this.$store.state.customer.error.response) {
          return this.$store.state.customer.error.response;
        }
        if (this.$store.state.customer.error.message) {
          return this.$store.state.customer.error.message;
        }
        if (this.$store.state.customer.error.error) {
          return this.$store.state.customer.error.error;
        }
        return JSON.stringify(this.$store.state.customer.error);
      }
      return null;
    },
    errorColor(): string {
      const eType = this.$store.state.customer?.error?.errorType;
      switch (eType) {
        case 'success':
          return eType;
        default:
          return 'error';
      }
    },
    helpAdminLink(): string {
      const agency = utils.getAgencyFromURL();

      const baseURL = useLink({
        product: 'compulse360',
        agency,
        env: utils.getEnvForProductLink(),
      });
      return `${baseURL}/help/request`;
    },
  },
  methods: {
    refreshApp(): void {
      this.updateExists = false;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const w = window as any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sw = w.dashboardServiceWorker as any;

      if (!sw || !sw.waiting) {
        return;
      }

      sw.waiting.postMessage('skipWaiting');
    },
    newBuildEvent(): void {
      this.newBuildLoading = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    showRefreshUI(e: any): void {
      const diff: number = new Date().getTime() - this.dateCreated;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const w = window as any;
      w.dashboardServiceWorker = e.detail;
      if (diff < 10000) {
        // eslint-disable-next-line no-console
        console.info('new version available, auto refresh ', diff / 1000.0, 's');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const sw = w.dashboardServiceWorker as any;
        sw.waiting.postMessage('skipWaiting');
        return;
      }
      this.updateExists = true;
    },
  },
};
